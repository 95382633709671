<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>
<form [formGroup]="parentForm">
    <div>
        <div class="card">
            <div class="card-header"><span>Личная информация</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="parentForm" [title]="'Фамилия'" [controlName]="'lastName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="parentForm" [title]="'Имя'" [controlName]="'firstName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="parentForm" [title]="'Отчество'" [controlName]="'middleName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-date-form-control
                                    [parentForm]="parentForm"
                                    [title]="'Дата рождения'"
                                    [controlName]="'dateOfBirth'"
                                    [maxDate]="dateOfBirthMaxDate"
                                ></app-date-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-dropdown-form-control [parentForm]="parentForm" [title]="'Тип'" [controlName]="'type'"></app-dropdown-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-phone-form-control [parentForm]="parentForm" [title]="'Мобильный телефон'" [controlName]="'phone1'"></app-phone-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-phone-form-control [parentForm]="parentForm" [title]="'Мобильный телефон'" [controlName]="'phone2'"></app-phone-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <app-name-form-control [parentForm]="parentForm" [title]="'Место работы'" [controlName]="'job'"></app-name-form-control>
                            </div>
                            <div class="col-md-6">
                                <app-name-form-control [parentForm]="parentForm" [title]="'Должность'" [controlName]="'jobPosition'"></app-name-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-info-form-control [parentForm]="parentForm" [title]="'Примечание'" [controlName]="'note'"></app-info-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12">
                                <app-checkbox-form-control [parentForm]="parentForm" [controlName]="'isActive'" [title]="'Действующий'"></app-checkbox-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-photo-form-control [size]="{ width: 'auto', height: '150px' }" [parentForm]="parentForm" [title]="'Фото'" [controlName]="'logo'"></app-photo-form-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2" *ngIf="isInitialized">
        <div class="card">
            <div class="card-header">
                <span>Дети</span>
                <button class="btn small svg-ic-add pull-right" (click)="addPupilItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid
                            #pupilGrid
                            [id]="'parentPupils'"
                            [columnDefinitions]="pupilColumnDefinitions"
                            [stateDataset]="pupils"
                            [formComponent]="pupilsFormModalComponent"
                        ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Дополнительные поля</span></div>
            <div class="card-body">
                <div class="row" *ngFor='let x of additionalFields;'>
                    <div class="col-md-12">
                        <app-name-form-control [parentForm]="parentForm" [title]="x.name" [controlName]="'additionalField' + x.id"></app-name-form-control>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
