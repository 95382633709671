import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { EmployeeListComponent } from './pages/employees/employee-list/employee-list.component';
import { AuthPermissionGuard } from './infrastructure/authentication/auth.permission.guard';
import { PupilListComponent } from './pages/pupils/pupil-list/pupil-list.component';
import { ParentListComponent } from './pages/parents/parent-list/parent-list.component';
import { EmployeeInformationComponent } from './pages/employees/employee-information/employee-information.component';
import { PupilInformationComponent } from './pages/pupils/pupil-information/pupil-information.component';
import { ParentInformationComponent } from './pages/parents/parent-information/parent-information.component';
import { GroupListComponent } from './pages/groups/group-list/group-list.component';
import { GroupInformationComponent } from './pages/groups/group-information/group-information.component';
import { CourseInformationComponent } from './pages/courses/course-information/course-information.component';
import { CourseListComponent } from './pages/courses/course-list/course-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AdditionalFieldListComponent } from './pages/additionalField/additionalField-list/additionalField-list.component';
import { AdditionalFieldInformationComponent } from './pages/additionalField/additionalField-information/additionalField-information.component';

const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Employee.View' },
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'employees',
        component: EmployeeListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Employee.View' },
    },
    {
        path: 'employees/:id',
        component: EmployeeInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Employee.View' },
    },
    {
        path: 'pupils',
        component: PupilListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Pupil.View' },
    },
    {
        path: 'pupils/:id',
        component: PupilInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Pupil.View' },
    },
    {
        path: 'parents',
        component: ParentListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Employee.View' },
    },
    {
        path: 'parents/:id',
        component: ParentInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Parent.View' },
    },
    {
        path: 'groups',
        component: GroupListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Group.View' },
    },
    {
        path: 'groups/:id',
        component: GroupInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Group.View' },
    },
    {
        path: 'courses',
        component: CourseListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Course.View' },
    },
    {
        path: 'courses/:id',
        component: CourseInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'Course.View' },
    },
    {
        path: 'additionalFields',
        component: AdditionalFieldListComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'AdditionalField.View' },
    },
    {
        path: 'additionalFields/:id',
        component: AdditionalFieldInformationComponent,
        canActivate: [AuthPermissionGuard],
        data: { permissionResource: 'AdditionalField.View' },
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
