import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUserCredentials } from 'src/app/models/user-credentials.model';

import { ConfigService } from '../../services/config.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private authUrl: string;
    private userInfoUrl: string;
   
    private authNavStatusSource = new BehaviorSubject<boolean>(false);
    authNavStatus$ = this.authNavStatusSource.asObservable();

    constructor(private configService: ConfigService, private http: HttpClient) {
        this.authUrl = `${this.configService.apiURI}/connect/token`;
        this.userInfoUrl = `${this.configService.apiURI}/connect/userinfo`;

        this.authNavStatusSource.next(this.isAuthenticated());
    }

    logout() {
        localStorage.removeItem("auth");
        localStorage.removeItem("userInfo");
        
        this.authNavStatusSource.next(this.isAuthenticated());
    }

    login(userCredentials: IUserCredentials) {

        this.getToken(userCredentials).subscribe((modal: any) => {
            localStorage.setItem("auth", JSON.stringify(modal));
            this.getUserInfo().subscribe((userInfo: any) => {
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                this.authNavStatusSource.next(this.isAuthenticated());
            });
        });
    }

    getToken(userCredentials: IUserCredentials): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const body = new HttpParams()
            .set('grant_type', 'password')
            .set('username', userCredentials.username)
            .set('password', userCredentials.password)
            .set('client_id', 'Api')
            .set('client_secret', '!QAZ2wsx');

        return this.http.post(this.authUrl, body.toString(), { headers });
    }

    getUserInfo(): Observable<any> {
        return this.http.get(this.userInfoUrl);
    }

    isAuthenticated(): boolean {
        const auth = JSON.parse(localStorage.getItem('auth'));

        return auth && auth['access_token'];
    }

    get authorizationHeaderValue(): string {
        const auth = JSON.parse(localStorage.getItem('auth'));

        return auth && auth['access_token'] ? `Bearer ${auth['access_token']}` : null;
    }

    get authorizationToken(): string {
        const auth = JSON.parse(localStorage.getItem('auth'));

        return auth && auth['access_token'] ? auth['access_token'] : null;
    }

    get permissions(): string {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo  != null ?  userInfo['permissions'] : null;
    }

    get userRole(): string {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo  != null ? userInfo['role'] : null;
    }

    get name(): string {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo  != null ? userInfo['name'] : null;
    }

    get userId(): number {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo != null) {
            const sub = userInfo['sub'];
            if (sub) {
                return Number.parseInt(sub, 10);
            }
        }

        return null;
    }
}
