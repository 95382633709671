<app-spinner></app-spinner>

<router-outlet *ngIf="!isAuthenticated"></router-outlet>

<div *ngIf="isAuthenticated">
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand" routerLink="/dashboard" routerLinkActive="active">Портал</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLink="/employees" routerLinkActive="active">
          <span class="nav-link cursor-pointer">Сотрудники</span>
        </li>
        <li class="nav-item" routerLink="/pupils" routerLinkActive="active">
          <span class="nav-link cursor-pointer">Воспитанники</span>
        </li>
        <li class="nav-item" routerLink="/parents" routerLinkActive="active">
          <span class="nav-link cursor-pointer">Родители</span>
        </li>
        <li class="nav-item" routerLink="/groups" routerLinkActive="active">
          <span class="nav-link cursor-pointer">Группы</span>
        </li>
        <li class="nav-item" routerLink="/courses" routerLinkActive="active">
          <span class="nav-link cursor-pointer">Кружки</span>
        </li>
        <li class="nav-item dropdown">
          <span class="nav-link dropdown-toggle cursor-pointer" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Настройки</span>
          <div class="dropdown-menu" aria-labelledby="dropdown01">
            <span class="dropdown-item cursor-pointer" routerLink="/additionalFields" routerLinkActive="active">Дополнительные поля</span>
          </div>
        </li>
      </ul>
  
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" (click)="logout()">
          <span class="nav-link cursor-pointer">Выход</span>
        </li>
      </ul>
  
    </div>
  </nav>
  
  <div class="container-fluid">
    <div class="row">
        <main role="main" class="col-12 p-0" style="margin-top: 56px !important;">
            <app-pages #page>
                <router-outlet (activate)="page.onActivate($event)" (deactivate)="page.onDeactivate($event)"></router-outlet>
            </app-pages>
        </main>
    </div>
  </div>
</div>