import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Column, FieldType, Filters, Formatter, GridState, OnEventArgs, OperatorType } from 'angular-slickgrid';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { IEmployee } from 'src/app/models/employee.model';
import { IEnum } from 'src/app/models/enum.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { CustomFormatter } from 'src/app/shared/components/grid/formatters';
import { dateFormatter, linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DisabledRowRule } from 'src/app/shared/components/grid/models/disabled-row-rule';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { EmployeesService } from '../employees.service';

@Component({
    selector: 'app-employees',
    templateUrl: './employee-list.component.html',
    styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('gridEmpList') grid: GridComponent;

    subTitle: string = '';
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    disabledRowRules: DisabledRowRule[];
    columnDefinitions: Column[];
    public gridState: GridState;

    constructor(
        private router: Router,
        public employeesService: EmployeesService,
        private additionalFieldsService: AdditionalFieldsService,
        private knowledgeBaseService: KnowledgeBaseService,
    ) {
        super('Сотрудники', false, false, router);
        
        this.subscriptionHandler.subscriptions = additionalFieldsService.getForEntityGrid('Employee').subscribe((model: IAdditionalField[]) => {
            this.initState();
            this.addAdditionalColumnDefinitions(model);
        });
    }

    get createLinkUrl(): string {
        return `/employees/create`;
    }

    initState(): void {
        this.disabledRowRules = [
            {
                properties: [{ property: 'isActive', value: false }],
            },
        ];

        this.columnDefinitions = [
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.lastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'JobPosition',
                name: 'Должность',
                field: 'jobPosition',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'DateOfBirth',
                name: 'Дата Рождения',
                field: 'dateOfBirth',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'StartOn',
                name: 'Дата Приема',
                field: 'startOn',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'EndOn',
                name: 'Дата Увольнения',
                field: 'endOn',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },          
            {
                id: 'IsActive',
                name: 'Активный',
                field: 'isActive',
                type: FieldType.boolean,
                sortable: true,
                filterable: true,
                filter: {
                    collection: [
                        { isActive: '', label: 'Выбрать все' },
                        { isActive: true, label: 'Активный' },
                        { isActive: false, label: 'Неактивный' },
                    ],
                    customStructure: {
                        value: 'isActive',
                        label: 'label',
                    },
                    model: Filters.singleSelect,
                },
                formatter: CustomFormatter.statusFormatter,
            }
        ];

        this.gridState = {
            filters: [
                {
                    columnId: 'IsActive',
                    operator: OperatorType.equal,
                    searchTerms: [true]
                },
            ],
        };
    }

    addAdditionalColumnDefinitions(additionalFields: IAdditionalField[]): void {
        if (additionalFields) {
            additionalFields.forEach(x => {
                this.columnDefinitions.push({
                    id: `AdditionalField.${x.id}`,
                    name: x.name,
                    field: `additionalField.${x.id}`,
                    type: FieldType.string,
                    formatter: CustomFormatter.additionalFieldFormatter,
                    sortable: false,
                    filterable: false,
                });
            });
        }
    }

    private lastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IEmployee;
       
        this.router.navigate([`/employees/${model.id}`]);
    }

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
