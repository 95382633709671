<div class="tabs-container">
    <div *ngIf="tabPages.length">
        <ul class="tabs group nav nav-tabs">
            <li class="nav-item rounded-0" *ngFor="let page of tabPages" [class.active]="page.url == location.path()" routerLink="{{ page.url }}">
                <span class="nav-link">
                    <span (appMiddleClick)="exit(page.url)">
                        {{ tabPage && tabPage.url == page.url && tabPage.title ? tabPage.title : page.title }}
                    </span>
                    <i class="fa fa-times" (click)="exit(page.url)"></i>
                </span>
            </li>
        </ul>
    </div>
    <div [ngClass]="{ 'tab-container': tabPage && tabPages.length }">
        <div class="scroll">
            <ng-content></ng-content>
        </div>
        <div *ngIf="tabPage && tabPages.length" class="pt-2 text-right">
            <button class="btn red" *ngIf="tabPage.isDeleteEnabled" [disabled]="tabPage.isDeleteDisabled" (click)="delete()">Удалить</button>
            <button class="btn green" *ngIf="tabPage.isSaveEnabled" [disabled]="tabPage.isSaveDisabled" (click)="save()">Сохранить</button>
        </div>
    </div>
</div>
