import { Component, Input } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { EmailFormControl } from './email-form-control';

@Component({
    selector: 'app-email-form-control',
    templateUrl: './email-form-control.component.html',
})
export class EmailFormControlComponent extends AbstractFormControlComponent<EmailFormControl> {
    public get minLength(): number {
        return this.control.minLength;
    }

    public get maxLength(): number {
        return this.control.maxLength;
    }
}
