import { SubscriptionHandler } from './../../../components/subscriptionHandler';
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { PhotoFormControl } from './photo-form-control';
import { getBase64 } from 'src/app/shared/extensions/extensions';
import { Photo } from 'src/app/models/photo.model';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-photo-form-control',
    templateUrl: './photo-form-control.component.html',
    styleUrls: ['./photo-form-control.component.scss'],
})
export class PhotoFormControlComponent extends AbstractFormControlComponent<PhotoFormControl> implements OnInit, OnDestroy {
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    noImage: Photo;

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public readonly controlId = uuidv4();

    constructor(private http: HttpClient, private cd: ChangeDetectorRef) {
        super();
        const self = this;
        this.http.get('/assets/images/no-avatar.png', { responseType: 'blob' }).subscribe((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                self.noImage = {
                    data: base64data,
                    contentType: 'base64data',
                    name: 'no-image.png',
                } as Photo;

                this.cd.detectChanges();
            };

            reader.readAsDataURL(res);
        });
    }

    @Input()
    size: any;

    fileType: string = '';

    private img: Photo = null;
    get image() {
        return this.img;
    }

    ngOnInit() {
        this.img = this.control.value;
        this.subscriptionHandler.subscriptions = this.control.valueChanges.subscribe((value: Photo) => (this.img = value));
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }

    public get maxSize(): number {
        return this.control.maxSize;
    }

    imageUpload = ($event) => {
        const inputValue = $event.target;
        const file: File = inputValue.files[0];
        const logoReader: FileReader = new FileReader();
        const self = this;

        logoReader.onloadend = (e$) => {
            self.img = {
                data: getBase64(Array.from(new Uint8Array(logoReader.result as any))),
                contentType: file.type,
                name: file.name,
            } as Photo;

            self.fileType = file.name.split('.')[1].toUpperCase();
            self.control.setValue(self.img);

            this.cd.detectChanges();
        };

        logoReader.readAsArrayBuffer(file);
    };

    onClearClick = () => {
        this.img = null;
        this.control.setValue(null);
    };
}
