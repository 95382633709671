<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <input matInput type="text" [validation]="false" mask="00000-0000" [class.is-invalid]="control.invalid && control.touched" class="form-control form-control-sm" [formControlName]="controlName" />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.zipCodeValidator">Формат: XXXXX or XXXXX-XXXX</span>
        </mat-error>
    </mat-form-field>
</div>
