import { Component, OnInit } from '@angular/core';
import { SubscriptionHandler } from './shared/components/subscriptionHandler';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from './services/config.service';
import { AuthService } from './infrastructure/authentication/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isAuthenticated = false;
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(
        private router: Router,
        private location: Location,
        private authService: AuthService,
        public configService: ConfigService
    ) {}

    ngOnInit() {
        this.subscriptionHandler.subscriptions = this.authService.authNavStatus$.subscribe((isAuthenticated) => {
            this.isAuthenticated = isAuthenticated;

            const path = this.location.path()

            if (!isAuthenticated) {
                this.router.navigate(['/login']);
            } else if (path.startsWith('/login')) {
                this.router.navigate(['/']);
            }
        });
    }

    logout() {
        this.authService.logout();
        
    }
}
