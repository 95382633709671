import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class CheckBoxFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private isTextInline: boolean = false) {
        super(required, disabled);
    }

    get isInline(): boolean {
        return this.isTextInline;
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}
