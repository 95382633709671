<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" class="info" [class.multiline]="isMultiline">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            appTrim
            *ngIf="!isMultiline"
            [class.is-invalid]="control.invalid && control.touched"
            type="text"
            class="form-control form-control-sm"
            [formControlName]="controlName"
        />
        <textarea
            matInput
            appTrim
            *ngIf="isMultiline"
            [class.is-invalid]="control.invalid && control.touched"
            class="form-control form-control-sm multiline"
            rows="5"
            [formControlName]="controlName"
        ></textarea>
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
        </mat-error>
    </mat-form-field>
</div>
