import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DateTimeFormControl } from './datetime-form-control';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
    selector: 'app-datetime-form-control',
    templateUrl: './datetime-form-control.component.html',
})
export class DateTimeFormControlComponent extends AbstractFormControlComponent<DateTimeFormControl> implements OnInit {
    @Input() max: Date | null = new Date();
    @Input() min: Date | null;
    @Input() hideTime: boolean = false;
    public enableMeridian: boolean = false;

    public value: Date = null;

    ngOnInit(): void {
        if (moment().format('LT').includes('M')) {
            // Check if local contains AM/PM in time string
            this.enableMeridian = true;
        }
    }

    changeDate(event: MatDatepickerInputEvent<Date>): void {
        const date = event.value as Date;

        this.control.checkMinDate(this.min);

        this.value = date;
        this.control.setValue(date);
        this.control.markAsTouched();
    }
}
