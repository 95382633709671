import { FormControl } from '@angular/forms';

export function fileTypeValidator(types: string[]) {
    return (control: FormControl) => {
        const file = control.value;
        if (file && file.name) {
            const splittedFileName = file.contentType.split('/');
            if (splittedFileName.length > 0) {
                const extension = splittedFileName[splittedFileName.length - 1];

                if (types.indexOf(extension.toLowerCase()) === -1) {
                    return {
                        fileTypeValidator: true,
                    };
                }
                return null;
            }
            return null;
        }
        return null;
    };
}
