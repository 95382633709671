import { ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { formatDate } from 'src/app/shared/extensions/extensions';
import { matDatepickerMaxValidator } from '../../validators/matDatepickerMaxValidator';
import { matDatepickerMinValidator } from '../../validators/matDatepickerMinValidator';

export class DateFormControl extends AbstractFormControl {
    constructor(required?: boolean, disabled?: boolean) {
        super(required, disabled);
        super.setValue(null);
    }

    protected getValidators(): ValidatorFn[] {
        return [matDatepickerMaxValidator(), matDatepickerMinValidator()];
    }

    setValue(
        value: any,
        options?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
            emitModelToViewChange?: boolean;
            emitViewToModelChange?: boolean;
        }
    ) {
        super.setValue(formatDate(value), options);
    }
}
