import { Component, OnInit } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ComboBoxFormControl } from './combobox-form-control';

@Component({
    selector: 'app-combobox-form-control',
    templateUrl: './combobox-form-control.component.html',
})
export class ComboBoxFormControlComponent extends AbstractFormControlComponent<ComboBoxFormControl> {
    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || 'name';
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || 'name';
    }
}
