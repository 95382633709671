<form (ngSubmit)="onConfirm()">
    <div class="modal-header" appNgxModalDraggable>
        <h4 class="modal-title pull-left">
            {{ title }}
        </h4>
        <button type="button" class="pull-right btn-modal-close" (click)="hideConfirmationModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHtml]="body"></p>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="submit" class="btn green">Ok</button>
        </div>
    </div>
</form>
