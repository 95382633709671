import { Component } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { InfoFormControl } from './info-form-control';

@Component({
    selector: 'app-info-form-control',
    templateUrl: './info-form-control.component.html',
})
export class InfoFormControlComponent extends AbstractFormControlComponent<InfoFormControl> {
    public get isMultiline(): boolean {
        return this.control.isMultiline;
    }
}
