<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>

<form [formGroup]="courseForm">
    <div>
        <div class="card">
            <div class="card-header"><span>Информация</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="courseForm" [title]="'Название'" [controlName]="'name'"></app-name-form-control>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
        </div>
    </div>  

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Воспитанники</span>
                <button class="btn small svg-ic-add pull-right" (click)="addPupilItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #pupilsGrid
                            [id]="'coursePupils'"
                            [enablePagination]="true"
                            [columnDefinitions]="pupilsColumnDefinitions"
                            [stateDataset]="pupils"
                            [formComponent]="pupilsFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>