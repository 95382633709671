import { Observable } from 'rxjs';

export abstract class DataService<T> {
    abstract odata(query: string): Observable<any>;

    protected getQueryBy(paramName: string, query: string): string {
        const matches = query.match(new RegExp(`(\\$${paramName}.*?)(?=&|$)`, 'g'));

        if (matches) {
            return matches[0];
        }
    }
}
