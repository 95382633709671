<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <mat-select [class.is-invalid]="control.invalid && control.touched" (selectionChange)="change($event)" class="form-control form-control-sm" [formControlName]="controlName">
            <mat-option *ngIf="disableChooseOption != true" [value]="null" selected></mat-option>
            <mat-option [value]="item[keyPropertyName]" *ngFor="let item of collection">
                {{useKeyForValue === true ? item[keyPropertyName] + " - ": ""}}{{ item[valuePropertyName] || item }}
            </mat-option>
            <mat-option [value]="item[keyPropertyName]" *ngFor="let item of hiddenOptions" [class.d-none]="true">
                {{useKeyForValue === true ? item[keyPropertyName] + " - ": ""}}{{ item[valuePropertyName] || item }}
            </mat-option>
        </mat-select>
        <mat-error>
            <span *ngIf="control.errors?.required">
                *Обязательно
                <span>{{ control.requiredMessage }}</span>
            </span>
        </mat-error>
    </mat-form-field>
</div>
