import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTitleCase]',
})
export class TitleCaseDirective {
    constructor(private ngControl: NgControl) {}

    @HostListener('window:keyup', ['$event']) OnBlur() {
        const value = this.ngControl.control.value;

        if (value) {
            const arr: string[] = value.split(' ');
            const res = [];

            arr.forEach((word) => {
                res.push(word.charAt(0).toUpperCase() + word.slice(1));
            });

            this.ngControl.control.patchValue(res.join(' '));
        }
    }
}
