import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IEnum } from 'src/app/models/enum.model';
import { IJobCategory } from 'src/app/models/jobCategory.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { DateFormControl } from 'src/app/shared/form/controls/date-form-control/date-form-control';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-jobCategory-form-modal',
    templateUrl: './jobCategory-form-modal.component.html',
    styleUrls: ['./jobCategory-form-modal.component.scss'],
})
export class JobCategoryFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        id: new NumberFormControl(false),
        type: new DropDownFormControl(true, { keyPropertyName: 'name', valuePropertyName: 'description' }),
        date: new DateFormControl(true),
        orderNumber: new NameFormControl(true),
    });

    public isFormDisabled: boolean = false;
    public minId: number = 0;

    constructor(private knowledgeBaseService: KnowledgeBaseService, private bsModalRef: BsModalRef) {
        super(bsModalRef);

        this.subscriptionHandler.subscriptions = knowledgeBaseService.jobCategoryType$.subscribe((model: IEnum[]) => {
            const control = this.form.get('type') as DropDownFormControl;
            control.setCollection(model);
        });
    }

    public show(collection: IJobCategory[], selectedItem: IJobCategory, empId: number) {
        if (collection && collection.length > 0) {
            this.minId = Math.min.apply(Math, collection.map(function(x) { return x.id; }));
        }
       
        if (selectedItem) {
            this.form.patchValue(cleanObject(selectedItem));
        } else {
        }
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.form.getRawValue() as IJobCategory;
        item.id = item.id ? item.id : (this.minId < 0 ? this.minId : this.minId * -1);
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
