<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            currencyMask
            [class.is-invalid]="control.invalid && control.touched"
            [options]="{
                prefix: '$',
                thousands: ',',
                decimal: '.',
                align: 'left',
                allowNegative: control.allowNegative
            }"
            type="text"
            class="form-control form-control-sm"
            [formControlName]="controlName"
        />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.min">
                <span *ngIf="!control.minValueErrorMessage">Min value should be {{ control.minValue }}</span>
                <span *ngIf="control.minValueErrorMessage">{{ control.minValueErrorMessage }}</span>
            </span>

            <span *ngIf="control.errors?.max">
                <span *ngIf="!control.maxValueErrorMessage">Max value should be {{ control.maxValue }}</span>
                <span *ngIf="control.maxValueErrorMessage">{{ control.maxValueErrorMessage }}</span>
            </span>
        </mat-error>
    </mat-form-field>
</div>
