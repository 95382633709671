<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>

<form [formGroup]="pupilForm">
    <div>
        <div class="card">
            <div class="card-header"><span>Личная информация</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="pupilForm" [title]="'Фамилия'" [controlName]="'lastName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="pupilForm" [title]="'Имя'" [controlName]="'firstName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="pupilForm" [title]="'Отчество'" [controlName]="'middleName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-date-form-control
                                    [parentForm]="pupilForm"
                                    [title]="'Дата рождения'"
                                    [controlName]="'dateOfBirth'"
                                    [maxDate]="dateOfBirthMaxDate"
                                ></app-date-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-dropdown-form-control [parentForm]="pupilForm" [title]="'Пол'" [controlName]="'gender'"></app-dropdown-form-control>
                            </div>
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-3">
                                <app-number-form-control
                                    [parentForm]="pupilForm"
                                    [title]="'Возраст'"
                                    [controlName]="'age'"
                                ></app-number-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-dropdown-form-control [parentForm]="pupilForm" [title]="'Группа'" [controlName]="'groupId'"></app-dropdown-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-date-form-control
                                    [parentForm]="pupilForm"
                                    [title]="'Дата приёма'"
                                    [controlName]="'startOn'"
                                ></app-date-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-date-form-control
                                    [parentForm]="pupilForm"
                                    [title]="'Дата ухода'"
                                    [controlName]="'endOn'"
                                ></app-date-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-checkbox-form-control [parentForm]="pupilForm" [controlName]="'pkpp'" [title]="'ПКПП'"></app-checkbox-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12">
                                <app-checkbox-form-control [parentForm]="pupilForm" [controlName]="'isActive'" [title]="'Действующий'"></app-checkbox-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-photo-form-control [size]="{ width: 'auto', height: '150px' }" [parentForm]="pupilForm" [title]="'Фото'" [controlName]="'logo'"></app-photo-form-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Паспортные данные</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <app-name-form-control [parentForm]="pupilForm" [title]="'Номер'" [controlName]="'passportNumber'"></app-name-form-control>
                    </div>
                    <div class="col-md-4">
                        <app-name-form-control [parentForm]="pupilForm" [title]="'Идентификационный номер'" [controlName]="'passportIndividualNumber'"></app-name-form-control>
                    </div>
                    <div class="col-md-4">
                        <app-date-form-control
                            [parentForm]="pupilForm"
                            [title]="'Выдан'"
                            [controlName]="'passportIssuedOn'"
                        ></app-date-form-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-name-form-control [parentForm]="pupilForm" [title]="'Прописка'" [controlName]="'passportRegistration'"></app-name-form-control>
                    </div>                           
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Родители</span>
                <button class="btn small svg-ic-add pull-right" (click)="addParentItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #parentsGrid
                            [id]="'pupilParents'"
                            [columnDefinitions]="parentsColumnDefinitions"
                            [stateDataset]="parents"
                            [formComponent]="parentsFormModalComponent"
                        ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Кружки</span>
                <button class="btn small svg-ic-add pull-right" (click)="addCourseItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #coursesGrid
                            [id]="'pupilCourses'"
                            [enablePagination]="true"
                            [columnDefinitions]="coursesColumnDefinitions"
                            [stateDataset]="courses"
                            [formComponent]="coursesFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Дополнительные поля</span></div>
            <div class="card-body">
                <div class="row" *ngFor='let x of additionalFields;'>
                    <div class="col-md-12">
                        <app-name-form-control [parentForm]="pupilForm" [title]="x.name" [controlName]="'additionalField' + x.id"></app-name-form-control>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
