<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            [class.is-invalid]="control.invalid && control.touched"
            [hiddenInput]="control.isHidden"
            mask="XXX-XX-0000"
            [patterns]="patterns"
            type="text"
            minlength="9"
            class="form-control form-control-sm"
            [formControlName]="controlName"
        />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.minlength">{{ title }} должен состоять из 9 цифр</span>
        </mat-error>
    </mat-form-field>
</div>
