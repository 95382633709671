import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { SpinnerService } from './services/spinner.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { AuthPermissionGuard } from './infrastructure/authentication/auth.permission.guard';
import { AuthInterceptor } from './infrastructure/authentication/auth.interceptor';
import { HttpErrorInterceptor } from './infrastructure/interceptors/http-error.interceptor';
import { SpinnerInterceptor } from './infrastructure/interceptors/spinner.interceptor';

import * as moment from 'moment';
import { PagesModule } from './pages/pages.module';

export function initConfig(configService: ConfigService) {
    return () => configService.loadConfig();
}

export function initMoment() {
    return () => moment.locale('ru');
}

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0,
    },
};

@NgModule({
    declarations: [ AppComponent ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        PagesModule,
        BrowserAnimationsModule,
        NoopAnimationsModule],
    providers: [
        SpinnerService,
        AuthPermissionGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true,
        },
        { provide: APP_INITIALIZER, useFactory: initMoment, multi: true },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: LOCALE_ID, useValue: 'ru-RU' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
