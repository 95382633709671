import { Router } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { BaseTabPageComponent } from './base-tab-page.component';
import { Subscription } from 'rxjs';
import { RouterState } from './router.state';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
    constructor(private bsModalService: BsModalService, private router: Router, public location: Location) {}

    public tabPage: BaseTabPageComponent;
    public tabPages: TabPage[] = [];
    routerState: RouterState;

    titleSubscription: Subscription;
    urlSubscription: Subscription;

    ngOnInit(): void {
        this.loadGlobalState();
    }

    onActivate(pageComponent: BaseTabPageComponent) {
        if (pageComponent instanceof BaseTabPageComponent) {
            const routerState = this.router.getCurrentNavigation().extras?.state as RouterState;
            if (routerState && routerState.url) {
                const page = this.getTabPageByUrl(routerState.url);
                page.url = pageComponent.url;
            } else {
                const page = this.getTabPageByUrl(pageComponent.url);
                if (page) {
                    pageComponent.title = page.title;
                } else {
                    this.tabPages.push({
                        url: pageComponent.url,
                        title: pageComponent.title,
                    });
                }
            }
        } else {
            console.log('To add page in the tabs use BaseTabPageComponent as a base class');
        }

        this.tabPage = pageComponent;

        if (this.tabPage && this.tabPage.title$) {
            this.titleSubscription = this.tabPage.title$.subscribe((x) => {
                if (x != null) {
                    const page = this.getTabPageByUrl(this.tabPage.url);
                    if (page) {
                        page.title = x;
                        this.saveGlobalState();
                    }
                }
            });
        }

        if (this.tabPage && this.tabPage.url$) {
            this.urlSubscription = this.tabPage.url$.subscribe((x) => {
                if (x != null) {
                    const page = this.getTabPageByUrl(x.prev);
                    if (page) {
                        page.url = x.next;
                        this.location.replaceState(page.url);
                        this.saveGlobalState();
                    }
                }
            });
        }

        this.saveGlobalState();
    }

    onDeactivate(pageComponent: BaseTabPageComponent) {
        if (pageComponent instanceof BaseTabPageComponent) {
            const page = this.getTabPageByUrl(pageComponent.url);
            if (page) {
                page.url = pageComponent.url;
                page.title = pageComponent.title;
            }
        } else {
        }

        if (this.titleSubscription) {
            this.titleSubscription.unsubscribe();
        }

        if (this.urlSubscription) {
            this.urlSubscription.unsubscribe();
        }

        this.tabPage = null;

        this.saveGlobalState();
    }

    save(): void {
        const save = this.tabPage.save();

        if (save instanceof Promise) {
            this.tabPage.setSaveDisabled(true);
            save.then((status: boolean) => {
                if (status) {
                    //this.tabPage.markAsPrestine();
                }
            }).finally(() => {
                this.tabPage.setSaveDisabled(false);
            });
        }
    }

    delete(): void {

        const modal = this.bsModalService.show(ConfirmationModalComponent);
        const modalContent = modal.content as ConfirmationModalComponent;
        modalContent.show('Удалить', 'Вы уверены?');

        modalContent.onClose.subscribe((result) => {
            if (result === true) {
                const deleteFunction = this.tabPage.delete();

                if (deleteFunction instanceof Promise) {
                    this.tabPage.setDeleteDisabled(true);
                    deleteFunction.then((status: boolean) => {
                        if (status) {
                            this.exit(this.tabPage.url);
                            //this.tabPage.markAsPrestine();
                        }
                    }).finally(() => {
                        this.tabPage.setDeleteDisabled(false);
                    });
                }
            } else if (result === false) {
                // when pressed No
            } else {
                // When closing the modal without no or yes
            }
        });
    }

    exit(url?: any): void {
        this.discardAndExit(url);
    }

    private discardAndExit(url: string): void {
        this.closeTab(url);
        this.tabPage.exit();
    }

    private closeTab(url?: any): void {
        if (url == null || (url && url === this.tabPage.url)) {
            this.tabPages = this.tabPages.filter((p) => p.url !== this.tabPage.url);

            if (this.tabPages.length) {
                this.router.navigate([this.tabPages[this.tabPages.length - 1].url]);
            } else {
                this.router.navigate(['/']);
            }
        } else {
            this.tabPages = this.tabPages.filter((p) => p.url !== url);
        }

        this.saveGlobalState();
    }

    private getTabPageByUrl(url: string): TabPage {
        return this.tabPages.find((p) => p.url === url);
    }

    private loadGlobalState() {
        this.tabPages = JSON.parse(localStorage.getItem(`tabPages`));
        if (this.tabPages == null) {
            this.tabPages = [];
        }
    }

    private saveGlobalState(): void {
        const tps = [];
        if (this.tabPages && this.tabPages.length) {
            this.tabPages.forEach((x) => {
                tps.push({
                    url: x.url,
                    title: x.title,
                });
            });
        }

        localStorage.setItem(`tabPages`, JSON.stringify(tps));
    }
}

export interface TabPage {
    title: string;
    url: string;
}