import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Column, FieldType, Filters, Formatters, OnEventArgs } from 'angular-slickgrid';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { IEntityAdditionalField } from 'src/app/models/entityAdditionalField.model';
import { IEnum } from 'src/app/models/enum.model';
import { IParentPupil } from 'src/app/models/parent-pupil.model';
import { IParent } from 'src/app/models/parent.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { ToastService } from 'src/app/services/toast.service';
import { dateFormatter, linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { CheckBoxFormControl } from 'src/app/shared/form/controls/checkbox-form-control/checkbox-form-control';
import { DateFormControl } from 'src/app/shared/form/controls/date-form-control/date-form-control';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { InfoFormControl } from 'src/app/shared/form/controls/info-form-control/info-form-control';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { PhotoFormControl } from 'src/app/shared/form/controls/photo-form-control/photo-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { PupilsService } from '../../pupils/pupils.service';
import { ParentsService } from '../parents.service';
import { ParentPupilFormModalComponent } from './modals/pupil-form-modal/parent-pupil-form-modal.component';

@Component({
  selector: 'app-parent-information',
  templateUrl: './parent-information.component.html',
  styleUrls: ['./parent-information.component.scss'],
})

export class ParentInformationComponent extends BaseTabPageComponent implements OnDestroy {
  @ViewChild('pupilGrid') pupilGrid: GridComponent;

  public parentForm: FormGroup = new FormGroup({
    id: new NumberFormControl(false, true),
    type: new DropDownFormControl(true, { keyPropertyName: 'name', valuePropertyName: 'description' }),
    firstName: new NameFormControl(true),
    middleName: new NameFormControl(false),
    lastName: new NameFormControl(true),
    dateOfBirth: new DateFormControl(true),
    job: new NameFormControl(true),
    jobPosition: new NameFormControl(true),
    phone1: new NameFormControl(false),
    phone2: new NameFormControl(false),
    note: new InfoFormControl(true, false),
    isActive: new CheckBoxFormControl(true),
    logo: new PhotoFormControl(false)
  });

  public subTitle: string = 'Родитель';
  public dateOfBirthMaxDate: Date = moment().toDate();
  public pupilColumnDefinitions: Column[];
  public pupils: IParentPupil[] = [];
  public isInitialized: boolean = false;

  public additionalFields: IAdditionalField[];
  private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  public pupilsFormModalComponent: any = ParentPupilFormModalComponent;

  constructor(
    public parentsService: ParentsService,
    public pupilsService: PupilsService,
    private additionalFieldsService: AdditionalFieldsService,
    private knowledgeBaseService: KnowledgeBaseService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
  ) {
    super(null, true, false, router);

    this.subscriptionHandler.subscriptions = knowledgeBaseService.parentType$.subscribe((model: IEnum[]) => {
      const control = this.parentForm.get('type') as DropDownFormControl;
      control.setCollection(model);
    });

    this.subscriptionHandler.subscriptions = this.additionalFieldsService.getForEntity('Parent')
    .subscribe((model: IAdditionalField[]) => {
      this.additionalFields = model;
      model.forEach((x) =>
        this.parentForm.addControl('additionalField' + x.id, new NameFormControl(false))
      );
    });

    this.init();
  }

  private init(): void {
    if (this.isCreationMode) {
      this.title = '(Р) Создание';

      const control = this.parentForm.get('isActive') as CheckBoxFormControl;
      control.patchValue(true);

      this.isInitialized = true;
    } else {
        this.subscriptionHandler.subscriptions = this.parentsService.get(this.getIdFromUrl()).subscribe((model: IParent) => {
            this.updateForm(model);                
        });
    }

    this.pupilColumnDefinitions = [
      {
        id: 'LastName',
        name: 'Фамилия',
        field: 'lastName',
        type: FieldType.string,
        sortable: true,
        filterable: true,
        formatter: linkFormatter,
        onCellClick: this.lastNameClick.bind(this),
      },
      {
        id: 'MiddleName',
        name: 'Отчество',
        field: 'middleName',
        type: FieldType.string,
        sortable: true,
        filterable: true,
      },
      {
        id: 'FirstName',
        name: 'Имя',
        field: 'firstName',
        type: FieldType.string,
        sortable: true,
        filterable: true,
      },
      {
        id: 'DateOfBirth',
        name: 'Дата Рождения',
        field: 'dateOfBirth',
        type: FieldType.date,
        sortable: true,
        filterable: true,
        formatter: dateFormatter,
        filter: {
          model: Filters.dateRange,
        }
      },
      {
        id: 'delete',
        field: 'id',
        formatter: Formatters.deleteIcon,
        minWidth: 30,
        maxWidth: 30,
        cssClass: 'text-body',
      }
    ];
  }

  public addPupilItem(): void {
    this.pupilGrid.addItem();
  }

  get isCreationMode(): boolean {
    return this.activeRoute.snapshot.paramMap.get('id') === 'create';
  }

  public save(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (!this.parentForm.valid) {
        this.toast.required();
        markFormGroupTouched(this.parentForm);
        resolve(false);

        return;
      }

      this.updateDataset();

      this.saveParent(resolve);
    });

    return promise;
  }

  delete(): void | Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
       
        const id = this.getIdFromUrl();
        if (id) {
            this.parentsService
            .delete(id)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: any) => {
                resolve(true);
                this.toast.success(`Родитель`, 'Удален');
            });
        }
    });

    return promise;
  }

  private updateDataset() {
    this.pupils = this.pupilGrid.getDataset();
}

  private saveParent(resolve): void {
    const row: any = this.parentForm.getRawValue();
    const parent: IParent = row as IParent;

    parent.pupils = this.pupils;

    parent.additionalFields = [];
    this.additionalFields.forEach((x) => {
      let field: IEntityAdditionalField = {};
      field.additionalFieldId = x.id;
      field.value = row['additionalField' + x.id];

      parent.additionalFields.push(field);
    });

    if (!parent.id) {
      this.parentsService
          .add(parent)
          .pipe(
              catchError((err) => {
                  resolve(false);
                  return throwError(err);
              })
          )
          .subscribe((model: IParent) => {
              resolve(true);
              this.updateNavigation(model);
              this.toast.success(`Родитель`, 'Добавлен');
          });

      return;
  }

  this.parentsService
      .update(parent.id, parent)
      .pipe(
          catchError((err) => {
              resolve(false);
              return throwError(err);
          })
      )
      .subscribe((model: IParent) => {
          resolve(true);
          this.updateNavigation(model);
          this.toast.success(`Родитель`, 'Изменен');
      });
  }

  private updateNavigation(model: IParent) {
    this.updateForm(model);
    this.url = `/parents/${model.id}`;
  }

  private getIdFromUrl(): number {
    return Number(this.activeRoute.snapshot.paramMap.get('id'));
  }

  private lastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
    const model = args.dataContext as IParentPupil;
    this.router.navigate([`/pupils/${model.id}`]);
  }

  private updateForm(model: IParent) {
    this.title = `(Р) ${model.lastName}, ${model.firstName}`;
    this.parentForm.patchValue(model);

    this.pupils = model.pupils;

    if (model.additionalFields) {
        model.additionalFields.forEach(x => {
            const control = this.parentForm.get('additionalField' + x.additionalFieldId) as NameFormControl;
            control.patchValue(x.value);
        });
    }

    this.isInitialized = true;
  }

  public ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}
