import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function matDatepickerMinValidator() {
    return (control: FormControl) => {
        const date = control.value;

        if (date && control['minDate']) {
            if (moment(date) < moment(control['minDate'])) {
                return {
                    matDatepickerMin: true,
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
