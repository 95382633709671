import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { Observable } from 'rxjs';
import { IParent } from 'src/app/models/parent.model';

@Injectable({
    providedIn: 'root',
})
export class ParentsService extends DataService<IParent> {
    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }
    
    get(id: number): Observable<IParent> {
        return this.http.get<IParent>(`${this.configService.apiURI}/api/parents/${id}`);
    }

    getforPupil(): Observable<IParent[]> {
        return this.http.get<IParent[]>(`${this.configService.apiURI}/api/parents/forPupil`);
    }

    update(id: number, data: IParent): Observable<IParent> {
        return this.http.put<IParent>(`${this.configService.apiURI}/api/parents/${id}`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/parents/${id}`);
    }

    add(data: any): Observable<IParent> {
        return this.http.post<IParent>(`${this.configService.apiURI}/api/parents/`, data);
    }

    odata(query: string): Observable<any> {
        return this.http.post(
            `${this.configService.apiURI}/odata/parents?$expand=additionalFields`,
            { query }
        );
    }
}
