import { IEnum } from './../models/enum.model';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseService {
    private url: string;

    private educationSpecialtyTypeSource = new ReplaySubject<IEnum[]>();
    private educationTypeSource = new ReplaySubject<IEnum[]>();
    private familyStatusTypesSource = new ReplaySubject<IEnum[]>();
    private genderTypesSource = new ReplaySubject<IEnum[]>();
    private jobCategoryType = new ReplaySubject<IEnum[]>();
    private parentType = new ReplaySubject<IEnum[]>();
    private userRoleTypesSource = new ReplaySubject<IEnum[]>();
    private additionalFieldEntityTypesSource = new ReplaySubject<IEnum[]>();
    private groupTypeSource = new ReplaySubject<IEnum[]>();
    
    // Observable navItem stream
    educationSpecialtyTypeSource$ = this.educationSpecialtyTypeSource.asObservable();
    educationTypeSource$ = this.educationTypeSource.asObservable();
    familyStatusTypesSource$ = this.familyStatusTypesSource.asObservable();
    genderTypesSource$ = this.genderTypesSource.asObservable();
    jobCategoryType$ = this.jobCategoryType.asObservable();
    parentType$ = this.parentType.asObservable();
    userRoleTypesSource$ = this.userRoleTypesSource.asObservable();
    additionalFieldEntityTypesSource$ = this.additionalFieldEntityTypesSource.asObservable();
    groupTypeSource$ = this.groupTypeSource.asObservable();

    constructor(private configService: ConfigService, private http: HttpClient) {
        this.url = `${this.configService.apiURI}/api/knowledgebase`;

        this.get();
    }

    private get(): void {
        this.http.get<IEnum[]>(`${this.url}`).subscribe((values: any) => {
            this.educationSpecialtyTypeSource.next(values['EducationSpecialtyType']);
            this.educationTypeSource.next(values['EducationType']);
            this.familyStatusTypesSource.next(values['FamilyStatusType']);
            this.genderTypesSource.next(values['GenderType']);
            this.jobCategoryType.next(values['JobCategoryType']);
            this.parentType.next(values['ParentType']);
            this.userRoleTypesSource.next(values['UserRoleType']);
            this.additionalFieldEntityTypesSource.next(values['AdditionalFieldEntityType']);
            this.groupTypeSource.next(values['GroupType']);
        });
    }
}
