import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function matDatepickerMaxValidator() {
    return (control: FormControl) => {
        const date = control.value;

        if (date && control['maxDate']) {
            if (moment(date) > moment(control['maxDate'])) {
                return {
                    matDatepickerMax: true,
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
