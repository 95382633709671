import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Column, FieldType, Filters, Formatter, OnEventArgs } from 'angular-slickgrid';
import { IEnum } from 'src/app/models/enum.model';
import { IGroup } from 'src/app/models/group.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DisabledRowRule } from 'src/app/shared/components/grid/models/disabled-row-rule';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { GroupsService } from '../../groups/groups.service';

@Component({
    selector: 'app-groups',
    templateUrl: './group-list.component.html',
    styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('gridGroupList') grid: GridComponent;

    subTitle: string = '';

    disabledRowRules: DisabledRowRule[];
    columnDefinitions: Column[];
        
    groupTypes: IEnum[] = [];

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(
        private router: Router,
        public groupsService: GroupsService,
        private knowledgeBaseService: KnowledgeBaseService,
    ) {
        super('Группы', false, false, router);
       
        this.subscriptionHandler.subscriptions = knowledgeBaseService.groupTypeSource$.subscribe((model: IEnum[]) => {
            this.groupTypes = model;
        });

        this.init();
    }

    get createLinkUrl(): string {
        return `/groups/create`;
    }

    init(): void {
        this.columnDefinitions = [
            {
                id: 'Number',
                name: 'Номер',
                field: 'number',
                type: FieldType.number,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.numberClick.bind(this),
            },
            {
                id: 'Type',
                name: 'Название',
                field: 'type',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.groupTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.groupTypeSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            }           
        ];
    }

    private numberClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IGroup;
       
        this.router.navigate([`/groups/${model.id}`]);
    }

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    
    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }

    private groupTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.groupTypes.find(i => i.name === value)?.description;
    };
}
