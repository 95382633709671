import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AlertModalComponent } from '../shared/components/modals/alert-modal/alert-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastrService: ToastrService, private bsModalService: BsModalService) {}

    success(entryType: string, actionTitle: string): void {
        let message = `${entryType} ${actionTitle}`;
        message = message[0].toUpperCase() + message.substr(1).toLowerCase();

        const title = actionTitle[0].toUpperCase() + actionTitle.substr(1).toLowerCase();

        this.toastrService.success(message, title, { enableHtml: true });
    }

    warning(message: string, title: string = 'Предупреждение'): void {
        this.toastrService.warning(message, 'Предупреждение', { enableHtml: true });
    }

    error(message: string, title: string = 'Ошибка'): void {
        this.toastrService.error(message, title, { enableHtml: true });
    }

    required(): void {
        this.toastrService.error('Пожалуйста, заполните все обязательные поля', 'Обязательные поля');
    }

    popup(errorMessage: string, title: string = 'Ошибка', callback: () => void = null): void {
        const modal = this.bsModalService.show(AlertModalComponent, {
            initialState: {
                title,
                body: errorMessage,
            },
        });

        const modalContent = modal.content as AlertModalComponent;
        modalContent.onClose.subscribe(() => {
            if (callback) {
                callback();
            }
        });
    }
}
