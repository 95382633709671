import { FormControl } from '@angular/forms';

export function fileSizeValidator() {
    return (control: FormControl) => {
        const file = control.value;
        if (file) {
            const fileSize = (3 * (file.data.length / 4)) / 1024;

            if (fileSize >= control['maxSize']) {
                return {
                    fileSizeValidator: true,
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
