import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { CheckBoxFormControl } from './checkbox-form-control';

@Component({
    selector: 'app-checkbox-form-control',
    templateUrl: './checkbox-form-control.component.html',
})
export class CheckBoxControlComponent extends AbstractFormControlComponent<CheckBoxFormControl> {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();

    change(): void {
        this.onChange.emit(this.control.value);
    }
}
