import { Component, Input } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NameFormControl } from './name-form-control';

@Component({
    selector: 'app-name-form-control',
    templateUrl: './name-form-control.component.html',
})
export class NameFormControlComponent extends AbstractFormControlComponent<NameFormControl> {
    public get maxLength(): number {
        return this.control.maxLength;
    }

    public get capitalizedEachWord(): boolean {
        return this.control.capitalizedEachWord || false;
    }

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
