import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { GridSearchComponent } from './components/grid/grid-search/grid-search.component';
import { GridComponent } from './components/grid/grid.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DatetimePipe } from './pipes/datetime.pipe';
import { NgxModalDraggableDirective } from './form/directives/ngx-modal-draggable.directive';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PagesComponent } from './components/pages/pages.component';
import { MiddleСlickDirective } from './directives/middleclick.directive';

@NgModule({
    declarations: [
        NgxModalDraggableDirective,
        PagesComponent,
        GridComponent,
        ConfirmationModalComponent,
        AlertModalComponent,
        GridSearchComponent,
        SpinnerComponent,
        DatetimePipe,
        NavigationComponent,
        MiddleСlickDirective
    ],
    exports: [
        NgxModalDraggableDirective,
        PagesComponent,
        GridComponent,
        ConfirmationModalComponent,
        AlertModalComponent,
        GridSearchComponent,
        SpinnerComponent,
        FormsModule,
        ReactiveFormsModule,
        DatetimePipe,
        NavigationComponent,
        MiddleСlickDirective
    ],
    imports: [
        CommonModule,

        FormsModule,
        ReactiveFormsModule,

        RouterModule,
        AngularSlickgridModule.forRoot({
            // add any Global Grid Options/Config you might want
            // to avoid passing the same options over and over in each grids of your App
            // enableAutoResize: true,
            // autoResize: {
            //   containerId: 'demo-container',
            //   sidePadding: 10
            // }
        }),
        ModalModule.forRoot(),
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
        }),
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
    ],
    // entryComponents: [
    //   ConfirmationModalComponent
    // ],
    providers: [BsModalRef, DatetimePipe, CurrencyPipe],
})
export class SharedModule {
    constructor(private modalService: BsModalService) {
        this.modalService.config.keyboard = false;
        this.modalService.config.backdrop = 'static';
        this.modalService.config.ignoreBackdropClick = true;
    }
}
