import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NumberFormControl } from './number-form-control';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';

@Component({
    selector: 'app-number-form-control',
    templateUrl: './number-form-control.component.html',
})
export class NumberFormControlComponent extends AbstractFormControlComponent<NumberFormControl> implements OnInit {
    public options: any = {
        prefix: '',
        thousands: '',
        decimal: '.',
        align: 'left',
        precision: 0,
        nullable: true,
    };

    public get minLength(): number {
        return this.control.minLength;
    }

    public get maxLength(): number {
        return this.control.maxLength;
    }

    ngOnInit(): void {
        if (this.control.onlyIntegers) {
            this.options.thousands = '';
            this.options.decimal = '';
        }
    }
}
