import { Validators, ValidatorFn } from '@angular/forms';
import { NameFormControl } from '../name-form-control/name-form-control';

export class InfoFormControl extends NameFormControl {
    constructor(private multiple: boolean = true, required: boolean = false) {
        super(required);
    }

    protected getValidators(): ValidatorFn[] {
        return [
            // Validators.maxLength(200)
        ];
    }

    public get isMultiline(): boolean {
        return this.multiple;
    }
}
