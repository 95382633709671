<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header" appNgxModalDraggable>
        <h4 class="modal-title pull-left">Категория</h4>
        <button type="button" class="pull-right btn-modal-close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div>
                <app-dropdown-form-control [parentForm]="form" [title]="'Тип'" [controlName]="'type'"></app-dropdown-form-control>
            </div>
            <div>
                <app-date-form-control [parentForm]="form" [title]="'Дата'" [controlName]="'date'"></app-date-form-control>
            </div>
            <div>
                <app-name-form-control [parentForm]="form" [title]="'Номер приказа'" [controlName]="'orderNumber'"></app-name-form-control>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="submit" class="btn green" [disabled]="!form.valid || isFormDisabled">OK</button>
            <button type="button" class="btn red" (click)="onCancel()">Отмена</button>
        </div>
    </div>
</form>
