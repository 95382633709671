import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

export abstract class FormBaseComponent {
    public onSave: Subject<any> = new Subject();
    public onClose$: Subject<void> = new Subject();
    selectedModel: any;
    form = new FormGroup({});

    constructor(private modalRef: BsModalRef) {}

    public show(collection: any[], selectedModel: any = null, additionalData: any = null) {
        this.selectedModel = selectedModel;
    }

    public onSubmit() {
        this.onSave.next(this.form.value);
        this.onSave.complete();
        this.hide();
    }

    public onCancel(): void {
        this.onClose$.next();
        this.onClose$.complete();
        this.hide();
    }

    public hide() {
        this.modalRef.hide();
    }
}
