import { Component, Input } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ZipCodeFormControl } from './zipcode-form-control';

@Component({
    selector: 'app-zipcode-form-control',
    templateUrl: './zipcode-form-control.component.html',
})
export class ZipCodeFormControlComponent extends AbstractFormControlComponent<ZipCodeFormControl> {
    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
