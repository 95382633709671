import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IInnovationActivity } from 'src/app/models/innovationActivity.model';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-innovationActivity-form-modal',
    templateUrl: './innovationActivity-form-modal.component.html',
    styleUrls: ['./innovationActivity-form-modal.component.scss'],
})
export class InnovationActivityFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        id: new NumberFormControl(false),
        name: new NameFormControl(true),
    });

    public isFormDisabled: boolean = false;
    public minId: number = 0;

    constructor(private bsModalRef: BsModalRef) {
        super(bsModalRef);
    }

    public show(collection: IInnovationActivity[], selectedItem: IInnovationActivity, empId: number) {
        if (collection && collection.length > 0) {
            this.minId = Math.min.apply(Math, collection.map(function(x) { return x.id; }));
        }
       
        if (selectedItem) {
            this.form.patchValue(cleanObject(selectedItem));
        } else {
        }
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.form.getRawValue() as IInnovationActivity;
        item.id = item.id ? item.id : (this.minId < 0 ? this.minId : this.minId * -1);
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
