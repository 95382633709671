import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IPupil } from 'src/app/models/pupil.model';
import { PupilsService } from 'src/app/pages/pupils/pupils.service';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-parent-pupil-form-modal',
    templateUrl: './parent-pupil-form-modal.component.html',
    styleUrls: ['./parent-pupil-form-modal.component.scss'],
})
export class ParentPupilFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        pupil: new DropDownFormControl(true, { keyPropertyName: 'value', valuePropertyName: 'name' }),
    });

    pupils: IPupil[] = [];

    public isFormDisabled: boolean = false;

    constructor(public pupilsService: PupilsService,
        private knowledgeBaseService: KnowledgeBaseService,
        private bsModalRef: BsModalRef) {
        super(bsModalRef);   
    }

    public show(collection: IPupil[], selectedItem: IPupil, id: number) {
        this.subscriptionHandler.subscriptions = this.pupilsService.getforParent().subscribe((model: IPupil[]) => {
           
            this.pupils = model.filter((b) => !collection.find((c) => c.id === b.id) || (selectedItem && selectedItem.id === b.id));
           
            let controlValues = [];

            if (this.pupils) {
                this.pupils.forEach(x => controlValues.push({name: `${x.lastName} ${x.firstName} ${x.middleName ?? ""} ${x.dateOfBirth ? new Date(x.dateOfBirth).toLocaleDateString('ru-RU'): ""}`, value: x.id}))
            }

            const control = this.form.get('pupil') as DropDownFormControl;
            control.setCollection(controlValues);
            
            if (selectedItem) {
                this.form.patchValue(cleanObject({name: `${selectedItem.lastName} ${selectedItem.firstName} ${selectedItem.middleName ?? ""} ${selectedItem.dateOfBirth ? new Date(selectedItem.dateOfBirth).toLocaleDateString('ru-RU') : ""}`, value: selectedItem.id}));
            }
        });
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.pupils.find(x => x.id == this.form.getRawValue().pupil)
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}