import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IEmployee } from 'src/app/models/employee.model';
import { EmployeesService } from 'src/app/pages/employees/employees.service';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-group-employee-form-modal',
    templateUrl: './group-employee-form-modal.component.html',
    styleUrls: ['./group-employee-form-modal.component.scss'],
})
export class GroupEmployeeFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        employee: new DropDownFormControl(true, { keyPropertyName: 'value', valuePropertyName: 'name' }),
    });

    employees: IEmployee[] = [];

    public isFormDisabled: boolean = false;

    constructor(public employeesService: EmployeesService, private bsModalRef: BsModalRef) {
        super(bsModalRef);        
    }

    public show(collection: IEmployee[], selectedItem: IEmployee, id: number) {
        this.subscriptionHandler.subscriptions = this.employeesService.getforGroup().subscribe((model: IEmployee[]) => {
           
            this.employees = model.filter((b) => !collection.find((c) => c.id === b.id) || (selectedItem && selectedItem.id === b.id));
           
            let controlValues = [];

            if (this.employees) {
                this.employees.forEach(x => controlValues.push({name: `${x.lastName} ${x.firstName}`, value: x.id}))
            }

            const control = this.form.get('employee') as DropDownFormControl;
            control.setCollection(controlValues);
            
            if (selectedItem) {
                this.form.patchValue(cleanObject({name: `${selectedItem.lastName} ${selectedItem.firstName}`, value: selectedItem.id}));
            }
        });
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.employees.find(x => x.id == this.form.getRawValue().employee)
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
