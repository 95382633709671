import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { Observable } from 'rxjs';
import { ICourse } from 'src/app/models/course.model';
import { replaceFilterQuery } from 'src/app/shared/extensions/extensions';

@Injectable({
    providedIn: 'root',
})
export class CoursesService extends DataService<ICourse> {
    
    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }

    get(id: number): Observable<ICourse> {
        return this.http.get<ICourse>(`${this.configService.apiURI}/api/courses/${id}`);
    }

    getforPupil(): Observable<ICourse[]> {
        return this.http.get<ICourse[]>(`${this.configService.apiURI}/api/courses/forPupil`);
    }

    update(id: number, data: any): Observable<ICourse> {
        return this.http.put<ICourse>(`${this.configService.apiURI}/api/courses/${id}`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/courses/${id}`);
    }

    add(data: any): Observable<ICourse> {
        return this.http.post<ICourse>(`${this.configService.apiURI}/api/courses/`, data);
    }

    odata(query: string): Observable<any> {
        query = this.formatQuery(query);
      
        return this.http.post(
            `${this.configService.apiURI}/odata/courses`,
            { query }
        );
    }

    formatQuery(query: string): string {
        const filterQuery = this.getQueryBy('filter', query);

        if (filterQuery) {
            let replacedFilterQuery = replaceFilterQuery(filterQuery, 'Pupils', 'Id');

            query = query.replace(filterQuery, replacedFilterQuery);
            console.log(query);
        }

        return query;
    }
}
