<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>

<form [formGroup]="additionalFieldForm">
    <div>
        <div class="card">
            <div class="card-header"><span>Информация</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <app-dropdown-form-control [parentForm]="additionalFieldForm" [title]="'Сущность'" [controlName]="'entityType'"></app-dropdown-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="additionalFieldForm" [title]="'Название'" [controlName]="'name'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-checkbox-form-control [parentForm]="additionalFieldForm" [title]="'Использовать в списке?'" [controlName]="'isShowInGrid'"></app-checkbox-form-control>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
        </div>
    </div>  
</form>