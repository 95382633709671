import { Directive, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[appPreventKeys]',
})
export class PreventKeyseDirective {
    @Input() appPreventKeys;

    @HostListener('keydown', ['$event']) onKeyUp($event) {
        if (this.appPreventKeys && this.appPreventKeys.includes($event.keyCode)) {
            $event.preventDefault();
        }
    }
}
