import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({ selector: '[appMiddleClick]' })
export class MiddleСlickDirective {
    @Output() appMiddleClick = new EventEmitter();

    constructor() {}

    @HostListener('mouseup', ['$event'])
    appMiddleClickEvent(event) {
        if (event.which === 2) {
            this.appMiddleClick.emit(event);
        }
    }
}
