import { Component, Input } from '@angular/core';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { PercentFormControl } from './percent-form.control';

@Component({
    selector: 'app-percent-form-control',
    templateUrl: './percent-form-control.component.html',
})
export class PercentFormControlComponent extends AbstractFormControlComponent<PercentFormControl> {
    options: any = {
        prefix: '',
        suffix: '%',
        thousands: ',',
        decimal: '.',
        align: 'left',
        allowNegative: true,
        allowZero: true,
        inputMode: CurrencyMaskInputMode.FINANCIAL,
        nullable: true,
        min: null,
        max: null,
    };

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
