import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datetime',
})
export class DatetimePipe implements PipeTransform {
    transform(value: Date, showTime: boolean = false): unknown {
        if (showTime) {
            return moment(value).format('L, LT');
        }
        return moment(value).format('L');
    }
}
