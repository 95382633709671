import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class NumberFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private min: number = 0, private max: number = 9, private onlyInt: boolean = false) {
        super(required, disabled);
        this.setValue(null);
        const validators = [Validators.minLength(this.min)];
        if (required) {
            validators.push(Validators.required);
        }
        this.setValidators(validators);
    }

    public get minLength(): number {
        return this.min;
    }

    public get maxLength(): number {
        return this.max;
    }

    public get onlyIntegers(): boolean {
        return this.onlyInt;
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }

    setValue(
        value: any,
        options?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
            emitModelToViewChange?: boolean;
            emitViewToModelChange?: boolean;
        }
    ) {
        if (typeof value === 'string') {
            if (value) {
                value = Number.parseFloat(value);
            } else {
                value = null;
            }
        }
        super.setValue(value, options);
    }
}
