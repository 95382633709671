import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/employee.model';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { Location } from '@angular/common';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { Column, FieldType, Filters, Formatter, Formatters, OnEventArgs, OperatorType } from 'angular-slickgrid';
import { EmployeesService } from '../../employees/employees.service';
import { linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { IEnum } from 'src/app/models/enum.model';
import { CoursesService } from '../courses.service';
import { ICourse } from 'src/app/models/course.model';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { IPupil } from 'src/app/models/pupil.model';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { PupilFormModalComponent } from './modals/pupil-form-modal/pupil-form-modal.component';
import { CustomFormatter } from 'src/app/shared/components/grid/formatters';
import { GroupsService } from '../../groups/groups.service';

@Component({
    selector: 'app-course-information',
    templateUrl: './course-information.component.html',
    styleUrls: ['./course-information.component.scss'],
})
export class CourseInformationComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('pupilsGrid') pupilsGrid: GridComponent;

    public isGridLoaded: boolean = false;

    public courseForm: FormGroup = new FormGroup({
        id: new NumberFormControl(false, true),
        name: new NameFormControl(true),
    });

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    subTitle: string = 'Кружок';

    pupilsColumnDefinitions: Column[];

    pupils: IPupil[] = [];
    public pupilsFormModalComponent: any = PupilFormModalComponent;

    genderTypes: IEnum[] = [];
    groupTypes: IEnum[] = [];

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        public employeesService: EmployeesService,
        private knowledgeBaseService: KnowledgeBaseService,
        public coursesService: CoursesService,
        public groupsService: GroupsService,
        private toast: ToastService,
        public location: Location
    ) {
        super(null, true, true, router);

        this.subscriptionHandler.subscriptions = knowledgeBaseService.genderTypesSource$.subscribe((model: IEnum[]) => {
            this.genderTypes = model;
        });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.groupTypeSource$.subscribe((model: IEnum[]) => {
            this.groupTypes = model;
        });
        
        this.init();
    }

    init(): void {
        if (this.isCreationMode) {
            this.title = 'Создание кружка';
            this.isGridLoaded = true;
        } else {
            this.subscriptionHandler.subscriptions = this.coursesService.get(this.getIdFromUrl()).subscribe((model: ICourse) => {
                this.updateForm(model);
            });
        }

        this.pupilsColumnDefinitions = [                 
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.pupilLastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'GroupId',
                name: 'Група',
                field: 'groupId',
                type: FieldType.number,
                sortable: true,
                filterable: true,
                formatter: this.groupFormatter,
                filter: {
                    collectionAsync: this.groupsService.short(),
                    customStructure: {
                        value: 'id',
                        label: 'name',
                        optionLabel: 'name', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Gender',
                name: 'Пол',
                field: 'gender',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.genderTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.genderTypesSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];
    }

    public addPupilItem(): void {
        this.pupilsGrid.addItem();
    }

    save(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
            if (!this.courseForm.valid) {
                this.toast.required();

                markFormGroupTouched(this.courseForm);

                resolve(false);

                return;
            }

            this.updateDataset();

            this.saveCourse(resolve);
        });

        return promise;
    }

    private updateDataset() {
        this.pupils = this.pupilsGrid.getDataset();
    }

    saveCourse(resolve): void {
        const course = this.courseForm.getRawValue() as ICourse;
        course.pupils = this.pupils;

        if (!course.id) {
            this.coursesService
                .add(course)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: ICourse) => {
                    resolve(true);
                    this.updateNavigation(model);
                    this.toast.success(`Кружок`, 'Добавлен');
                });

            return;
        }

        this.coursesService
            .update(course.id, course)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: ICourse) => {
                resolve(true);
                this.updateNavigation(model);
                this.toast.success(`Кружок`, 'Изменен');
            });
    }

    delete(): void | Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
           
            const id = this.getIdFromUrl();
            if (id) {
                this.coursesService
                .delete(id)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: any) => {
                    resolve(true);
                    this.toast.success(`Кружок`, 'Удален');
                });
            }
        });

        return promise;
    }

    get isCreationMode(): boolean {
        return this.activeRoute.snapshot.paramMap.get('id') === 'create';
    }

    private updateNavigation(model: ICourse) {
        this.updateForm(model);
        this.url = `/courses/${model.id}`;
    }

    private updateForm(model: ICourse) {
        this.title = `(К) ${model.name}`;
        this.courseForm.patchValue(model);
        
        this.pupils = model.pupils;

        this.isGridLoaded = true;
    }

    private getIdFromUrl(): number {
        return Number(this.activeRoute.snapshot.paramMap.get('id'));
    }

    private pupilLastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IEmployee;
       
        this.router.navigate([`/pupils/${model.id}`]);
    }

    private genderTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.genderTypes.find(i => i.name === value).description;
    };

    private groupFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return dataContext.group ? dataContext.group.number + ' - ' + this.groupTypes.find(i => i.name === dataContext.group.type).description : "";
    };

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
