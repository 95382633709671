import { Input } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { AbstractFormControl } from './abstract-form-control';

export abstract class AbstractFormControlComponent<T extends AbstractFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;

    get isRequired(): boolean {
        return this.control.isRequired;
    }

    get isDisabled(): boolean {
        return this.control.isDisabled;
    }

    get control(): T {
        return this.parentForm.get(this.controlName) as T;
    }
}
