import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFormControlComponent } from './controls/name-form-control/name-form-control.component';
import { DateFormControlComponent } from './controls/date-form-control/date-form-control.component';
import { EmailFormControlComponent } from './controls/email-form-control/email-form-control.component';
import { PhoneFormControlComponent } from './controls/phone-form-control/phone-form-control.component';
import { NumberFormControlComponent } from './controls/number-form-control/number-form-control.component';
import { ZipCodeFormControlComponent } from './controls/zipcode-form-control/zipcode-form-control.component';
import { InfoFormControlComponent } from './controls/info-form-control/info-form-control.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DropDownFormControlComponent } from './controls/dropdown-form-control/dropdown-form-control.component';
import { CheckBoxControlComponent } from './controls/checkbox-form-control/checkbox-form-control.component';
import { NgxMaskModule } from 'ngx-mask';
import { TrimDirective } from './directives/trim.directive';
import { TitleCaseDirective } from './directives/title-case.directive';
import { PreventKeyseDirective } from './directives/prevent-keys.directive';
import { PhotoFormControlComponent } from './controls/photo-form-control/photo-form-control.component';
import { CurrencyFormControlComponent } from './controls/currency-form-control/currency-form-control.component';
import { SsnFormControlComponent } from './controls/ssn-form-control/ssn-from-control.component';
import { ComboBoxFormControlComponent } from './controls/combobox-form-control/combobox-form-control.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { PercentFormControlComponent } from './controls/percent-form-control/percent-form-control.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { DateTimeFormControlComponent } from './controls/datetime-form-control/datetime-form-control.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { TrimAllDirective } from './directives/trim-all.directive';
import { NumberSearchControlComponent } from './controls/search/number-search-control/number-search-control.component';

@NgModule({
    declarations: [
        NameFormControlComponent,
        DateFormControlComponent,
        EmailFormControlComponent,
        PhoneFormControlComponent,
        NumberFormControlComponent,
        ZipCodeFormControlComponent,
        InfoFormControlComponent,
        DropDownFormControlComponent,
        CheckBoxControlComponent,
        TrimDirective,
        TrimAllDirective,
        TitleCaseDirective,
        PreventKeyseDirective,
        PhotoFormControlComponent,
        CurrencyFormControlComponent,
        SsnFormControlComponent,
        ComboBoxFormControlComponent,
        PercentFormControlComponent,
        DateTimeFormControlComponent,
        NumberSearchControlComponent,
    ],
    exports: [
        NameFormControlComponent,
        DateFormControlComponent,
        EmailFormControlComponent,
        PhoneFormControlComponent,
        NumberFormControlComponent,
        ZipCodeFormControlComponent,
        InfoFormControlComponent,
        DropDownFormControlComponent,
        CheckBoxControlComponent,
        PhotoFormControlComponent,
        CurrencyFormControlComponent,
        SsnFormControlComponent,
        ComboBoxFormControlComponent,
        PercentFormControlComponent,
        DateTimeFormControlComponent,
        NumberSearchControlComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        NgxMaskModule.forRoot(),
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        NgxMatMomentModule,
        NgxCurrencyModule,
    ],
    // and in the module providers

    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'L, LT',
                },
                display: {
                    dateInput: 'L, LT',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class FormModule {}
