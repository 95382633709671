import { FormControl, Validators, ValidatorFn } from '@angular/forms';

export abstract class AbstractFormControl extends FormControl {
    constructor(private required: boolean = false, disabled: boolean = false) {
        super({ value: '', disabled });

        const validators = this.getValidators();
        if (required) {
            validators.push(Validators.required);
        }
        this.setValidators(validators);
    }

    public get isRequired(): boolean {
        return this.required;
    }

    public get isDisabled(): boolean {
        return this.disabled;
    }

    public markAsRequred(): void {
        this.required = true;
        const validators = this.getValidators();
        validators.push(Validators.required);
        this.setValidators(validators);
        this.updateValueAndValidity();
    }

    public markAsNotRequred(): void {
        this.required = false;
        this.setValidators(this.getValidators());
        this.updateValueAndValidity();
    }

    protected abstract getValidators(): ValidatorFn[];
}
