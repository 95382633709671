import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Column, Editors, FieldType, Filters, Formatter, Formatters, GridState, OnEventArgs, SortDirection } from 'angular-slickgrid';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { IEnum } from 'src/app/models/enum.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { CustomFormatter } from 'src/app/shared/components/grid/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { AdditionalFieldsService } from '../additionalFields.service';

@Component({
    selector: 'app-additionalFields',
    templateUrl: './additionalField-list.component.html',
    styleUrls: ['./additionalField-list.component.scss'],
})
export class AdditionalFieldListComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('gridAdditionalFieldList') grid: GridComponent;

    subTitle: string = '';

    columnDefinitions: Column[];
    public additionalFieldsGridState: GridState;

    additionalFieldEntityTypes: IEnum[] = [];

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(
        private router: Router,
        public additionalFieldsService: AdditionalFieldsService,
        private knowledgeBaseService: KnowledgeBaseService
    ) {
        super('Дополнительные поля', false, false, router);
       
        this.subscriptionHandler.subscriptions = knowledgeBaseService.additionalFieldEntityTypesSource$.subscribe((model: IEnum[]) => {
            this.additionalFieldEntityTypes = model;
        });

        this.init();
    }

    get createLinkUrl(): string {
        return `/additionalFields/create`;
    }

    init(): void {
        this.columnDefinitions = [
            {
                id: 'EntityType',
                name: 'Сущность',
                field: 'entityType',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.entityTypeFormatter,
                onCellClick: this.entityTypeClick.bind(this),
                filter: {
                    collectionAsync: this.knowledgeBaseService.additionalFieldEntityTypesSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'IsShowInGrid',
                name: 'В списке',
                field: 'isShowInGrid',
                type: FieldType.boolean,
                sortable: true,
                filterable: true,
                filter: {
                    collection: [
                        { value: '', label: 'Выбрать все' },
                        { value: true, label: 'Да' },
                        { value: false, label: 'Нет' },
                    ],
                    customStructure: {
                        value: 'value',
                        label: 'label',
                    },
                    model: Filters.singleSelect,
                },
                formatter: CustomFormatter.additionalFieldInGridFormatter,
            }          
        ];

        this.additionalFieldsGridState = {
            sorters: [
                {
                    columnId: 'EntityType',
                    direction: SortDirection.ASC,
                },
            ]
        };
    }

    private entityTypeClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IAdditionalField;
       
        this.router.navigate([`/additionalFields/${model.id}`]);
    }

    private entityTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        const v = this.additionalFieldEntityTypes.find(i => i.name === value).description;
        return v ? `<a class="cursor-pointer">${v}</a>` : '';
    };

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    
    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
