<main class="form-signin">
   <h1 class="h3 mb-3 fw-normal">ДЦРР №1 - Портал</h1>
   <div class="form-group">
         <label>Пользователь</label>
         <input type="text" class="form-control" [(ngModel)]="username">
      </div>
      <div class="form-group">
         <label>Пароль</label>
         <input type="password" class="form-control" [(ngModel)]="password">
      </div>
   <button type="button" class="w-100 btn btn-lg blue" (click)="login()">Вход</button>
 </main>