import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/employee.model';
import { ToastService } from 'src/app/services/toast.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { CheckBoxFormControl } from 'src/app/shared/form/controls/checkbox-form-control/checkbox-form-control';
import { DateFormControl } from 'src/app/shared/form/controls/date-form-control/date-form-control';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { EmployeesService } from '../employees.service';
import { Location } from '@angular/common';
import { PhotoFormControl } from 'src/app/shared/form/controls/photo-form-control/photo-form-control';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { IEnum } from 'src/app/models/enum.model';
import { Column, FieldType, Filters, Formatter, Formatters } from 'angular-slickgrid';
import { GroupsService } from '../../groups/groups.service';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { ITraining } from 'src/app/models/training.model';
import { IJobCategory } from 'src/app/models/jobCategory.model';
import { IEducation } from 'src/app/models/education.model';
import { IEmployeeChild } from 'src/app/models/employeeChild.model';
import { JobCategoryFormModalComponent } from './modals/jobCategory-form-modal/jobCategory-form-modal.component';
import { dateFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { ChildFormModalComponent } from './modals/child-form-modal/child-form-modal.component';
import { EducationFormModalComponent } from './modals/education-form-modal/education-form-modal.component';
import { TrainingFormModalComponent } from './modals/training-form-modal/training-form-modal.component';
import { IEntityAdditionalField } from 'src/app/models/entityAdditionalField.model';
import { IGroupShort } from 'src/app/models/group-short.model';
import { IPublicOrganization } from 'src/app/models/publicOrganization.model';
import { IInnovationActivity } from 'src/app/models/innovationActivity.model';
import { InnovationActivityFormModalComponent } from './modals/innovationActivity-form-modal/innovationActivity-form-modal.component';
import { PublicOrganizationFormModalComponent } from './modals/publicOrganization-form-modal/publicOrganization-form-modal.component';

@Component({
    selector: 'app-employee-information',
    templateUrl: './employee-information.component.html',
    styleUrls: ['./employee-information.component.scss'],
})
export class EmployeeInformationComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('jobCategoriesGrid') jobCategoriesGrid: GridComponent;
    @ViewChild('educationsGrid') educationsGrid: GridComponent;
    @ViewChild('trainingsGrid') trainingsGrid: GridComponent;
    @ViewChild('employeeChildrenGrid') employeeChildrenGrid: GridComponent;
    @ViewChild('publicOrganizationsGrid') publicOrganizationsGrid: GridComponent;
    @ViewChild('innovationActivitiesGrid') innovationActivitiesGrid: GridComponent;

    public isGridLoaded: boolean = false;

    public employeeForm: FormGroup = new FormGroup({
        id: new NumberFormControl(false, true),
        isActive: new CheckBoxFormControl(true),
       
        lastName: new NameFormControl(true),
        middleName: new NameFormControl(false),
        firstName: new NameFormControl(true),
        
        logo: new PhotoFormControl(false),
        
        jobPosition: new NameFormControl(true),
       
        dateOfBirth: new DateFormControl(true),
        
        mobilePhone1: new NameFormControl(false),
        mobilePhone2: new NameFormControl(false),
        homePhone: new NameFormControl(false),
      
        address: new NameFormControl(false),
        
        startOn: new DateFormControl(true),
        endOn: new DateFormControl(false),

        jobEducationExperience: new NumberFormControl(false),
        jobPositionExperience: new NumberFormControl(false),

        passportId: new NumberFormControl(false, true),
        passportIndividualNumber: new NameFormControl(true),
        passportNumber: new NameFormControl(true),
        passportIssuedOn: new DateFormControl(true),
        passportRegistration: new NameFormControl(true),

        groupId: new DropDownFormControl(false, { keyPropertyName: 'id', valuePropertyName: 'name', useKeyForValue: false }),
    });

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    subTitle: string = 'Сотрудник';

    dateOfBirthMaxDate: Date = moment().toDate();

    categoriesColumnDefinitions: Column[];
    educationsColumnDefinitions: Column[];
    trainingsColumnDefinitions: Column[];
    childrenColumnDefinitions: Column[];
    publicOrganizationsColumnDefinitions: Column[];
    innovationActivitiesColumnDefinitions: Column[];

    jobCategoryTypes: IEnum[] = [];
    educationSpecialtyTypes: IEnum[] = [];
    educationTypes: IEnum[] = [];

    employeeChildren: IEmployeeChild[] = [];
    educations: IEducation[] = [];
    jobCategories: IJobCategory[] = [];
    trainings: ITraining[] = [];
    publicOrganizations: IPublicOrganization[] = [];
    innovationActivities: IInnovationActivity[] = [];
   
    additionalFields: IAdditionalField[];

    public jobCategoriesFormModalComponent: any = JobCategoryFormModalComponent;
    public educationsFormModalComponent: any = EducationFormModalComponent;
    public trainingsFormModalComponent: any = TrainingFormModalComponent;
    public employeeChildrenFormModalComponent: any = ChildFormModalComponent;
    public publicOrganizationsFormModalComponent: any = PublicOrganizationFormModalComponent;
    public innovationActivitiesFormModalComponent: any = InnovationActivityFormModalComponent;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        public employeesService: EmployeesService,
        public groupsService: GroupsService,
        private additionalFieldsService: AdditionalFieldsService,
        private knowledgeBaseService: KnowledgeBaseService,
        private toast: ToastService,
        public location: Location
    ) {
        super(null, true, true, router);

        this.subscriptionHandler.subscriptions = knowledgeBaseService.jobCategoryType$.subscribe((model: IEnum[]) => {
            this.jobCategoryTypes = model;
        });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.educationSpecialtyTypeSource$.subscribe((model: IEnum[]) => {
            this.educationSpecialtyTypes = model;
        });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.educationTypeSource$.subscribe((model: IEnum[]) => {
            this.educationTypes = model;
        });

        this.subscriptionHandler.subscriptions = this.groupsService.short().subscribe((values: IGroupShort[]) => {
            const control = this.employeeForm.get('groupId') as DropDownFormControl;
            control.setCollection(values);
        });

        this.subscriptionHandler.subscriptions = additionalFieldsService.getForEntity('Employee').subscribe((model: IAdditionalField[]) => {
           this.additionalFields = model;
           
            model.forEach((x) =>
                this.employeeForm.addControl('additionalField' + x.id, new NameFormControl(false))
            );
        });

        this.init();
    }

    init(): void {
        if (this.isCreationMode) {
            this.title = '(С) Создание';

            const control = this.employeeForm.get('isActive') as CheckBoxFormControl;
            control.patchValue(true);

            this.isGridLoaded = true;
        } else {
            this.subscriptionHandler.subscriptions = this.employeesService.get(this.getIdFromUrl()).subscribe((model: IEmployee) => {
                this.updateForm(model);
            });
        }

        this.categoriesColumnDefinitions = [
            {
                id: 'Type',
                name: 'Тип',
                field: 'type',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.categoryTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.jobCategoryType$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Date',
                name: 'Дата',
                field: 'date',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'OrderNumber',
                name: 'Номер приказа',
                field: 'orderNumber',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'edit',
                field: 'id',
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.educationsColumnDefinitions = [
            {
                id: 'Type',
                name: 'Тип',
                field: 'type',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.educationTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.educationTypeSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Specialty',
                name: 'Специальность',
                field: 'specialty',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.educationSpecialtyTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.educationSpecialtyTypeSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Name',
                name: 'Учреждение',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'EndOnYear',
                name: 'Год окончания',
                field: 'endOnYear',
                type: FieldType.number,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Qualification',
                name: 'Квалификация',
                field: 'qualification',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'edit',
                field: 'id',
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];
        
        this.trainingsColumnDefinitions = [
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Year',
                name: 'Год',
                field: 'year',
                type: FieldType.number,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Place',
                name: 'Учреждение',
                field: 'place',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'edit',
                field: 'id',
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.childrenColumnDefinitions = [
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'DateOfBirth',
                name: 'Дата Рождения',
                field: 'dateOfBirth',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'PlaceOfStudy',
                name: 'Место учебы',
                field: 'placeOfStudy',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'edit',
                field: 'id',
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.publicOrganizationsColumnDefinitions = [
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.innovationActivitiesColumnDefinitions = [
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];
    }

    public addJobCategoryItem(): void {
        this.jobCategoriesGrid.addItem();
    }

    public addEducationItem(): void {
        this.educationsGrid.addItem();
    }

    public addTrainingItem(): void {
        this.trainingsGrid.addItem();
    }

    public addEmployeeChildItem(): void {
        this.employeeChildrenGrid.addItem();
    }

    public addPublicOrganizationItem(): void {
        this.publicOrganizationsGrid.addItem();
    }

    public addInnovationActivityItem(): void {
        this.innovationActivitiesGrid.addItem();
    }

    save(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
            if (!this.employeeForm.valid) {
                this.toast.required();

                markFormGroupTouched(this.employeeForm);

                resolve(false);

                return;
            }

            this.updateDataset();

            this.saveEmployee(resolve);
        });

        return promise;
    }

    delete(): void | Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
           
            const id = this.getIdFromUrl();
            if (id) {
                this.employeesService
                .delete(id)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: any) => {
                    resolve(true);
                    this.toast.success(`Сотрудник`, 'Удален');
                });
            }
        });

        return promise;
    }

    private updateDataset() {
        this.employeeChildren = this.employeeChildrenGrid.getDataset();
        this.educations = this.educationsGrid.getDataset();
        this.jobCategories = this.jobCategoriesGrid.getDataset();
        this.trainings = this.trainingsGrid.getDataset();
        this.publicOrganizations = this.publicOrganizationsGrid.getDataset();
        this.innovationActivities = this.innovationActivitiesGrid.getDataset();
    }

    saveEmployee(resolve): void {
        const row: any = this.employeeForm.getRawValue();
        const employee: IEmployee = row as IEmployee;
        employee.passport = {};
        employee.passport.id = row.passportId;
        employee.passport.individualNumber = row.passportIndividualNumber;
        employee.passport.issuedOn = row.passportIssuedOn;
        employee.passport.number = row.passportNumber;
        employee.passport.registration = row.passportRegistration;
       
        employee.employeeChildren = this.employeeChildren;
        employee.educations = this.educations;
        employee.jobCategories = this.jobCategories;
        employee.trainings = this.trainings;
        employee.publicOrganizations = this.publicOrganizations;
        employee.innovationActivities = this.innovationActivities;

        employee.additionalFields = [];
        this.additionalFields.forEach((x) => {
            let field: IEntityAdditionalField = {}; 
            field.additionalFieldId = x.id;
            field.value = row['additionalField' + x.id];

            employee.additionalFields.push(field);
        });

        if (!employee.id) {
            this.employeesService
                .add(employee)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: IEmployee) => {
                    resolve(true);
                    this.updateNavigation(model);
                    this.toast.success(`Сотрудник`, 'Добавлен');
                });

            return;
        }

        this.employeesService
            .update(employee.id, employee)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: IEmployee) => {
                resolve(true);
                this.updateNavigation(model);
                this.toast.success(`Сотрудник`, 'Изменен');
            });
    }

    get isCreationMode(): boolean {
        return this.activeRoute.snapshot.paramMap.get('id') === 'create';
    }

    private updateNavigation(model: IEmployee) {
        this.updateForm(model);
        this.url = `/employees/${model.id}`;
    }

    private updateForm(model: IEmployee)
    {
        this.title = `(С) ${model.lastName}, ${model.firstName}`;
        this.employeeForm.patchValue(model);
        
        if (model.passport) {
            this.employeeForm.patchValue({
                passportId: model.passport.id,
                passportIndividualNumber: model.passport.individualNumber,
                passportNumber: model.passport.number,
                passportIssuedOn: model.passport.issuedOn,
                passportRegistration: model.passport.registration,
            });
        }

        if (model.group) {
            const control = this.employeeForm.get('groupId') as DropDownFormControl;
            control.patchValue(model.group.id);
        }

        if (model.additionalFields) {
            model.additionalFields.forEach(x => {
                const control = this.employeeForm.get('additionalField' + x.additionalFieldId) as NameFormControl;
                control.patchValue(x.value);
            });
        }

        this.employeeChildren = model.employeeChildren;
        this.educations = model.educations;
        this.jobCategories = model.jobCategories;
        this.trainings = model.trainings;
        this.publicOrganizations = model.publicOrganizations;
        this.innovationActivities = model.innovationActivities;

        this.isGridLoaded = true;
    }

    private getIdFromUrl(): number {
        return Number(this.activeRoute.snapshot.paramMap.get('id'));
    }

    private categoryTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.jobCategoryTypes.find(i => i.name === value).description;
    };

    private educationTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.educationTypes.find(i => i.name === value).description;
    };

    private educationSpecialtyTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.educationSpecialtyTypes.find(i => i.name === value).description;
    };

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
