import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class DropDownFormControl extends AbstractFormControl {
    constructor(required: boolean = false, private collectionDescription: CollectionDescription = {}, disabled: boolean = false, private options?: DropDownOptions) {
        super(required, disabled);
        this.setValue(null);

        if (required) {
            this.collectionDescription.disableChooseOption = true;
        }

        if (collectionDescription.collection) {
            this.setCollection(collectionDescription.collection);
        }
    }

    markAsRequred(): void {
        this.collectionDescription.disableChooseOption = true;
        super.markAsRequred();
    }

    markAsNotRequred(): void {
        this.collectionDescription.disableChooseOption = false;
        super.markAsNotRequred();
    }

    setCollection(collection: any[]) {
        this.collectionDescription.collection = collection;

        if (this.collectionDescription.hiddenOptions) {
            const foundOption = this.collectionDescription.collection.find((item: any) => item[this.keyPropertyName] === this.collectionDescription.hiddenOptions[0][this.keyPropertyName]);
            if (foundOption) {
                this.collectionDescription.hiddenOptions = null;
            }
        }

        if (!this.value && this.collectionDescription.enableFirstChooseOption && this.collectionDescription.collection?.length) {
            this.setValue(this.collectionDescription.collection[0][this.collectionDescription.keyPropertyName]);
        }
    }

    removeFirstChooseOption(): void {
        if (this.collectionDescription) {
            this.setValue(null);
            this.collectionDescription.enableFirstChooseOption = false;
        }
    }

    addFirstChooseOption(): void {
        if (this.collectionDescription) {
            this.collectionDescription.enableFirstChooseOption = true;
            this.setCollection(this.collectionDescription.collection);
        }
    }

    setHiddenOption(option: any): void {
        if (this.collectionDescription) {
            if (this.collectionDescription.collection) {
                const foundOption = this.collectionDescription.collection.find((item: any) => item[this.keyPropertyName] === option[this.keyPropertyName]);
                if (!foundOption) {
                    this.collectionDescription.hiddenOptions = [option];
                }
            } else {
                this.collectionDescription.hiddenOptions = [option];
            }
        }
    }

    get requiredMessage(): string {
        return this.collectionDescription?.requiredMessage;
    }

    get collection(): any[] {
        return this.collectionDescription.collection;
    }

    get hiddenOptions(): any {
        return this.collectionDescription.hiddenOptions;
    }

    get keyPropertyName(): string {
        return this.collectionDescription.keyPropertyName;
    }

    get valuePropertyName(): string {
        return this.collectionDescription.valuePropertyName;
    }

    get useKeyForValue(): boolean {
        return this.collectionDescription.useKeyForValue;
    }

    get disableChooseOption(): boolean {
        return this.collectionDescription.disableChooseOption;
    }

    get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}

export interface CollectionDescription {
    keyPropertyName?: string;
    valuePropertyName?: string;
    collection?: any[];
    disableChooseOption?: boolean;
    enableFirstChooseOption?: boolean;
    hiddenOptions?: any[];
    requiredMessage?: string;
    useKeyForValue?: boolean
}

export interface DropDownOptions {
    readOnlyCss?: boolean;
}
