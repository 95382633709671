import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { IConfiguration } from '../models/configuration';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private config: IConfiguration;
    public configSubject$: ReplaySubject<IConfiguration> = new ReplaySubject<IConfiguration>();

    constructor(private http: HttpClient) {}

    get apiURI() {
        return window.location.origin;
    }

    public loadConfig() {
        return this.http.get(`${window.location.origin}/api/configuration`).subscribe((config: IConfiguration) => {
            this.config = config;
            this.configSubject$.next(this.config);
        });
    }

    public get appVersion(): string {
        return this.config != null ? this.config.appVersion : '';
    }

    public getConfig() {
        return this.config;
    }
}
