<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>

<form [formGroup]="employeeForm">
    <div>
        <div class="card">
            <div class="card-header"><span>Личная информация</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="employeeForm" [title]="'Фамилия'" [controlName]="'lastName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="employeeForm" [title]="'Имя'" [controlName]="'firstName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-name-form-control [parentForm]="employeeForm" [title]="'Отчество'" [controlName]="'middleName'"></app-name-form-control>
                            </div>
                            <div class="col-md-3">
                                <app-date-form-control
                                    [parentForm]="employeeForm"
                                    [title]="'Дата рождения'"
                                    [controlName]="'dateOfBirth'"
                                    [maxDate]="dateOfBirthMaxDate"
                                ></app-date-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <app-phone-form-control [parentForm]="employeeForm" [title]="'Мобильный телефон'" [controlName]="'mobilePhone1'"></app-phone-form-control>
                            </div>
                            <div class="col-md-4">
                                <app-phone-form-control [parentForm]="employeeForm" [title]="'Мобильный телефон'" [controlName]="'mobilePhone2'"></app-phone-form-control>
                            </div>
                            <div class="col-md-4">
                                <app-phone-form-control [parentForm]="employeeForm" [title]="'Домашний телефон'" [controlName]="'homePhone'"></app-phone-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-name-form-control [parentForm]="employeeForm" [title]="'Адрес'" [controlName]="'address'"></app-name-form-control>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12">
                                <app-checkbox-form-control [parentForm]="employeeForm" [controlName]="'isActive'" [title]="'Действующий'"></app-checkbox-form-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-photo-form-control [size]="{ width: 'auto', height: '150px' }" [parentForm]="employeeForm" [title]="'Фото'" [controlName]="'logo'"></app-photo-form-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Работа</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <app-name-form-control [parentForm]="employeeForm" [title]="'Должность'" [controlName]="'jobPosition'"></app-name-form-control>
                    </div>
                    <div class="col-md-4">
                        <app-dropdown-form-control [parentForm]="employeeForm" [title]="'Группа'" [controlName]="'groupId'"></app-dropdown-form-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <app-date-form-control
                            [parentForm]="employeeForm"
                            [title]="'Дата приёма'"
                            [controlName]="'startOn'"
                        ></app-date-form-control>
                    </div>
                    <div class="col-md-3">
                        <app-date-form-control
                            [parentForm]="employeeForm"
                            [title]="'Дата увольнения'"
                            [controlName]="'endOn'"
                        ></app-date-form-control>
                    </div>
                    <div class="col-md-3">
                        <app-number-form-control [parentForm]="employeeForm" [title]="'Стаж в образовании'" [controlName]="'jobEducationExperience'"></app-number-form-control>
                    </div>
                    <div class="col-md-3">
                        <app-number-form-control [parentForm]="employeeForm" [title]="'Стаж в должности'" [controlName]="'jobPositionExperience'"></app-number-form-control>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Категории</span>
                <button class="btn small svg-ic-add pull-right" (click)="addJobCategoryItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #jobCategoriesGrid
                            [id]="'empCategories'"
                            [columnDefinitions]="categoriesColumnDefinitions"
                            [stateDataset]="jobCategories"
                            [formComponent]="jobCategoriesFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Паспортные данные</span></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <app-name-form-control [parentForm]="employeeForm" [title]="'Номер'" [controlName]="'passportNumber'"></app-name-form-control>
                    </div>
                    <div class="col-md-4">
                        <app-name-form-control [parentForm]="employeeForm" [title]="'Идентификационный номер'" [controlName]="'passportIndividualNumber'"></app-name-form-control>
                    </div>
                    <div class="col-md-4">
                        <app-date-form-control
                            [parentForm]="employeeForm"
                            [title]="'Выдан'"
                            [controlName]="'passportIssuedOn'"
                        ></app-date-form-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-name-form-control [parentForm]="employeeForm" [title]="'Прописка'" [controlName]="'passportRegistration'"></app-name-form-control>
                    </div>                           
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Образование</span>
                <button class="btn small svg-ic-add pull-right" (click)="addEducationItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #educationsGrid
                            [id]="'empEducations'"
                            [columnDefinitions]="educationsColumnDefinitions"
                            [stateDataset]="educations"
                            [formComponent]="educationsFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Повышение квалификации</span>
                <button class="btn small svg-ic-add pull-right" (click)="addTrainingItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #trainingsGrid
                            [id]="'empTrainings'"
                            [columnDefinitions]="trainingsColumnDefinitions"
                            [stateDataset]="trainings"
                            [formComponent]="trainingsFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Общественные организации</span>
                <button class="btn small svg-ic-add pull-right" (click)="addPublicOrganizationItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #publicOrganizationsGrid
                            [id]="'empPublicOrganizations'"
                            [columnDefinitions]="publicOrganizationsColumnDefinitions"
                            [stateDataset]="publicOrganizations"
                            [formComponent]="publicOrganizationsFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Инновационная деятельность</span>
                <button class="btn small svg-ic-add pull-right" (click)="addInnovationActivityItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #innovationActivitiesGrid
                            [id]="'empInnovationActivities'"
                            [columnDefinitions]="innovationActivitiesColumnDefinitions"
                            [stateDataset]="innovationActivities"
                            [formComponent]="innovationActivitiesFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header">
                <span>Дети</span>
                <button class="btn small svg-ic-add pull-right" (click)="addEmployeeChildItem()"></button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <app-grid *ngIf="isGridLoaded"
                            #employeeChildrenGrid
                            [id]="'empChildren'"
                            [columnDefinitions]="childrenColumnDefinitions"
                            [stateDataset]="employeeChildren"
                            [formComponent]="employeeChildrenFormModalComponent"
                            ></app-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pt-2">
        <div class="card">
            <div class="card-header"><span>Дополнительные поля</span></div>
            <div class="card-body">
                <div class="row" *ngFor='let x of additionalFields;'>
                    <div class="col-md-12">
                        <app-name-form-control [parentForm]="employeeForm" [title]="x.name" [controlName]="'additionalField' + x.id"></app-name-form-control>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
