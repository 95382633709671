import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { CheckBoxFormControl } from 'src/app/shared/form/controls/checkbox-form-control/checkbox-form-control';
import { DateFormControl } from 'src/app/shared/form/controls/date-form-control/date-form-control';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { Location } from '@angular/common';
import { PhotoFormControl } from 'src/app/shared/form/controls/photo-form-control/photo-form-control';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { IEnum } from 'src/app/models/enum.model';
import { Column, FieldType,  Filters,  Formatter,  Formatters,  OnEventArgs } from 'angular-slickgrid';
import { PupilsService } from '../pupils.service';
import { IPupil } from 'src/app/models/pupil.model';
import { GroupsService } from '../../groups/groups.service';
import { IGroup } from 'src/app/models/group.model';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { dateFormatter, linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { ICourse } from 'src/app/models/course.model';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { IParent } from 'src/app/models/parent.model';
import { PupilParentFormModalComponent } from './modals/parent-form-modal/pupil-parent-form-modal.component';
import { PupilCourseFormModalComponent } from './modals/course-form-modal/pupil-course-form-modal.component';
import { IEntityAdditionalField } from 'src/app/models/entityAdditionalField.model';

@Component({
    selector: 'app-pupil-information',
    templateUrl: './pupil-information.component.html',
    styleUrls: ['./pupil-information.component.scss'],
})
export class PupilInformationComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('parentsGrid') parentsGrid: GridComponent;
    @ViewChild('coursesGrid') coursesGrid: GridComponent;

    public isGridLoaded: boolean = false;

    public pupilForm: FormGroup = new FormGroup({
        id: new NumberFormControl(false, true),
        isActive: new CheckBoxFormControl(true),
       
        lastName: new NameFormControl(true),
        middleName: new NameFormControl(false),
        firstName: new NameFormControl(true),
        
        logo: new PhotoFormControl(false),
        
        gender: new DropDownFormControl(true, { keyPropertyName: 'name', valuePropertyName: 'description' }),
       
        dateOfBirth: new DateFormControl(true),
        age: new NumberFormControl(false),
        pkpp: new CheckBoxFormControl(false),
        
        startOn: new DateFormControl(true),
        endOn: new DateFormControl(false),

        passportIndividualNumber: new NameFormControl(false),
        passportNumber: new NameFormControl(false),
        passportIssuedOn: new DateFormControl(false),
        passportRegistration: new NameFormControl(false),

        groupId: new DropDownFormControl(false, { keyPropertyName: 'id', valuePropertyName: 'name', useKeyForValue: false }),
    });

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    subTitle: string = 'Воспитанник';

    dateOfBirthMaxDate: Date = moment().toDate();

    coursesColumnDefinitions: Column[];
    parentsColumnDefinitions: Column[];

    parents: IParent[] = [];
    courses: ICourse[] = [];
    additionalFields: IAdditionalField[];

    public parentsFormModalComponent: any = PupilParentFormModalComponent;
    public coursesFormModalComponent: any = PupilCourseFormModalComponent;

    parentTypes: IEnum[] = [];

    constructor(
        public pupilsService: PupilsService,
        public groupsService: GroupsService,
        public location: Location,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private knowledgeBaseService: KnowledgeBaseService,
        private additionalFieldsService: AdditionalFieldsService,
        private toast: ToastService
    ) {
        super(null, true, true, router);
       
        this.subscriptionHandler.subscriptions = knowledgeBaseService.genderTypesSource$.subscribe((model: IEnum[]) => {
            const control = this.pupilForm.get('gender') as DropDownFormControl;
            control.setCollection(model);
        });

        this.subscriptionHandler.subscriptions = this.groupsService.short().subscribe((values: IGroup[]) => {
            const control = this.pupilForm.get('groupId') as DropDownFormControl;
            control.setCollection(values);
        });

        this.subscriptionHandler.subscriptions = additionalFieldsService.getForEntity('Pupil').subscribe((model: IAdditionalField[]) => {
            this.additionalFields = model;
            
             model.forEach((x) =>
                 this.pupilForm.addControl('additionalField' + x.id, new NameFormControl(false))
             );
         });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.parentType$.subscribe((model: IEnum[]) => {
            this.parentTypes = model;
        });

        this.init();
    }

    init(): void {
        if (this.isCreationMode) {
            this.title = 'Создание';

        const control = this.pupilForm.get('isActive') as CheckBoxFormControl;
        control.patchValue(true);

        const controlPkpp = this.pupilForm.get('pkpp') as CheckBoxFormControl;
        controlPkpp.patchValue(false);

            this.isGridLoaded = true;
        } else {
            this.subscriptionHandler.subscriptions = this.pupilsService.get(this.getIdFromUrl()).subscribe((model: IPupil) => {
                this.updateForm(model);                
            });
        }

        this.parentsColumnDefinitions = [
            {
                id: 'Type',
                name: 'Тип',
                field: 'type',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.parentTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.parentType$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.lastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'DateOfBirth',
                name: 'Дата Рождения',
                field: 'dateOfBirth',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.coursesColumnDefinitions = [
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.nameClick.bind(this),
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },                
        ];
    }

    public addParentItem(): void {
        this.parentsGrid.addItem();
    }

    public addCourseItem(): void {
        this.coursesGrid.addItem();
    }

    save(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
            if (!this.pupilForm.valid) {
                this.toast.required();

                markFormGroupTouched(this.pupilForm);

                resolve(false);

                return;
            }

            this.updateDataset();

            this.savePupil(resolve);
        });

        return promise;
    }

    private updateDataset() {
        this.parents = this.parentsGrid.getDataset();
        this.courses = this.coursesGrid.getDataset();
    }

    delete(): void | Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
           
            const id = this.getIdFromUrl();
            if (id) {
                this.pupilsService
                .delete(id)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: any) => {
                    resolve(true);
                    this.toast.success(`Воспитанник`, 'Удален');
                });
            }
        });

        return promise;
    }

    savePupil(resolve): void {
        const row: any = this.pupilForm.getRawValue();
        const pupil: IPupil = row as IPupil;
       
        pupil.passport = null;
        if (row.passportIndividualNumber) {
            pupil.passport = {};
            pupil.passport.id = row.passportId;
            pupil.passport.individualNumber = row.passportIndividualNumber;
            pupil.passport.issuedOn = row.passportIssuedOn;
            pupil.passport.number = row.passportNumber;
            pupil.passport.registration = row.passportRegistration;
        }

        pupil.parents = this.parents;
        pupil.courses = this.courses;

        pupil.additionalFields = [];
        this.additionalFields.forEach((x) => {
            let field: IEntityAdditionalField = {};
            field.additionalFieldId = x.id;
            field.value = row['additionalField' + x.id];

            pupil.additionalFields.push(field);
        });

        if (!pupil.id) {
            this.pupilsService
                .add(pupil)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: IPupil) => {
                    resolve(true);
                    this.updateNavigation(model);
                    this.toast.success(`Воспитанник`, 'Добавлен');
                });

            return;
        }

        this.pupilsService
            .update(pupil.id, pupil)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: IPupil) => {
                resolve(true);
                this.updateNavigation(model);
                this.toast.success(`Воспитанник`, 'Изменен');
            });
    }

    get isCreationMode(): boolean {
        return this.activeRoute.snapshot.paramMap.get('id') === 'create';
    }

    private updateNavigation(model: IPupil) {
        this.updateForm(model);
        this.url = `/pupils/${model.id}`;
    }

    private updateForm(model: IPupil) {
        this.title = `(В) ${model.lastName}, ${model.firstName}`;
        this.pupilForm.patchValue(model);
        
        if (model.passport) {
            this.pupilForm.patchValue({
                passportIndividualNumber: model.passport.individualNumber,
                passportNumber: model.passport.number,
                passportIssuedOn: model.passport.issuedOn,
                passportRegistration: model.passport.registration,
            });
        }

        if (model.group) {
            const control = this.pupilForm.get('groupId') as DropDownFormControl;
            control.patchValue(model.group.id);
        }       

        if (model.additionalFields) {
            model.additionalFields.forEach(x => {
                const control = this.pupilForm.get('additionalField' + x.additionalFieldId) as NameFormControl;
                control.patchValue(x.value);
            });
        }

        this.parents = model.parents;
        this.courses = model.courses;

        this.isGridLoaded = true;
    }

    private getIdFromUrl(): number {
        return Number(this.activeRoute.snapshot.paramMap.get('id'));
    }

    
    private nameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as ICourse;
       
        this.router.navigate([`/courses/${model.id}`]);
    }

    private lastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IPupil;
        this.router.navigate([`/parents/${model.id}`]);
    }

    private parentTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.parentTypes.find(i => i.name === value).description;
    };

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
