<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill">
        <mat-label>{{ title }}</mat-label>
        <input
            (dateChange)="changeDate($event)"
            [disabled]="control.disabled"
            [ngxMatDatetimePicker]="picker"
            class="form-control form-control-sm mat-input-element mat-form-field-autofill-contro"
            [formControlName]="controlName"
            [min]="min"
            [max]="max"
            [value]="value"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [enableMeridian]="enableMeridian" [hideTime]="hideTime"></ngx-mat-datetime-picker>
        <input matInput class="d-none" [formControlName]="controlName" />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.matDatetimePickerMin">Дата должна быть не меньше текущей даты</span>
        </mat-error>
    </mat-form-field>
</div>
