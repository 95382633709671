import { Formatter, Column, FieldType, Formatters } from 'angular-slickgrid';
import * as moment from 'moment';
import { formatCurrency } from '@angular/common';

export const phoneFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
    if (!value) {
        return '';
    }

    const code = value.substr(0, 3);
    const firstPart = value.substr(3, 3);
    const secondPart = value.substr(6, 4);

    if (dataContext && dataContext.phoneType) {
        return `(${code}) ${firstPart}-${secondPart} (${dataContext.phoneType})`;
    } else {
        return `(${code}) ${firstPart}-${secondPart}`;
    }
};

export const accountNumberFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
    if (!value) {
        return '';
    }

    return `<a class='pointer'>${value}</a>`;
};

export const commentFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
    if (!value) {
        return '';
    }

    const textPreviewLength = 20;

    if (value.length <= textPreviewLength) {
        return `${value}`;
    }

    const text = value.substr(0, textPreviewLength) + '...';
    return `${text} <a title='${value}'>See More</a>`;
};

export const activeContactFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
    if ((dataContext.promiseDate && moment().isBefore(moment(dataContext.promiseDate))) || (dataContext.contactDate && moment().isBefore(moment(dataContext.contactDate)))) {
        return `<b class='text-green'>${value}</b>`;
    }

    return value;
};

export const dateTimeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
    if (value && columnDef.type === FieldType.dateTime) {
        value = moment(value).format('L, LT');
    }

    return value;
};

export const dateFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
    if (value && columnDef.type === FieldType.date) {
        value = moment(value).format("DD.MM.YYYY");
    }

    return value;
};

export const emptyFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
    return '';
};

export const currencyFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
    return value != null ? formatCurrency(value, 'en-US', '$') : '-';
};

export const customReverseButtonFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
    return `<i class="fa fa-undo pointer"></i>`;
};

export const linkFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
    return value ? `<a class="cursor-pointer">${value}</a>` : '';
};