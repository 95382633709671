import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ICourse } from 'src/app/models/course.model';
import { CoursesService } from 'src/app/pages/courses/courses.service';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-pupil-course-form-modal',
    templateUrl: './pupil-course-form-modal.component.html',
    styleUrls: ['./pupil-course-form-modal.component.scss'],
})
export class PupilCourseFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        course: new DropDownFormControl(true, { keyPropertyName: 'id', valuePropertyName: 'name' }),
    });

    courses: ICourse[] = [];

    public isFormDisabled: boolean = false;

    constructor(public coursesService: CoursesService, private bsModalRef: BsModalRef) {
        super(bsModalRef);        
    }

    public show(collection: ICourse[], selectedItem: ICourse, id: number) {
        this.subscriptionHandler.subscriptions = this.coursesService.getforPupil().subscribe((model: ICourse[]) => {
           
            this.courses = model.filter((b) => !collection.find((c) => c.id === b.id) || (selectedItem && selectedItem.id === b.id));
           
            const control = this.form.get('course') as DropDownFormControl;
            control.setCollection(this.courses);
            
            if (selectedItem) {
                this.form.patchValue(cleanObject({name: `${selectedItem.name}`, id: selectedItem.id}));
            }
        });
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.courses.find(x => x.id == this.form.getRawValue().course)
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
