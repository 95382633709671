import { Component } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { CurrencyFormControl } from './currency-form.control';

@Component({
    selector: 'app-currency-form-control',
    templateUrl: './currency-form-control.component.html',
})
export class CurrencyFormControlComponent extends AbstractFormControlComponent<CurrencyFormControl> {
    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
