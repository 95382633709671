import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularGridInstance, AngularUtilService, Column, CurrentFilter, Filters, GridOption, GridState, OdataOption } from 'angular-slickgrid';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { constants } from '../../constants/constants';
import { DataService } from './data.service';
import { FormBaseComponent } from './modals/form-base.component';
import { IActionClickedEvent } from './models/action-clicked-event';
import { DisabledRowRule } from './models/disabled-row-rule';
import { IRowSelectedEvent } from './models/row-selected-event';
import { GridOdataService } from './services/grid-odata.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

const defaultPageSize = 20;

// using external non-typed js libraries
declare var $: any;

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
    @Input() id: string;

    @Input() service: DataService<any> | null;

    @Input() datasetIdPropertyName: string | 'id';
    @Input() disabledRowRules: DisabledRowRule[] | null;
    @Input() columnDefinitions: Column[];
    @Input() state: GridState | null;

    @Input() enablePagination: boolean | true;

    @Input() enableFiltering: boolean = true;

    @Input() enableHeaderMenu: boolean = true;

    @Input() stateDataset: any[] = [];

    @Input() formComponent: any;
    @Input() formData: any;

    @Input() enableRowNavigation: boolean = true;

    @Output() rowClick: EventEmitter<IRowSelectedEvent> = new EventEmitter();
    @Output() actionClick: EventEmitter<IActionClickedEvent> = new EventEmitter();
    @Output() rowSelect: EventEmitter<any> = new EventEmitter();
    @Output() rowDelete: EventEmitter<any> = new EventEmitter();

    @Input() enableCheckboxSelector: boolean = false;

    public angularGrid: AngularGridInstance;
    public gridOptions: GridOption;
    public dataset: any[] = [];
    onDblClicked: boolean;

    constructor(
        private angularUtilService: AngularUtilService,
        private bsModalService: BsModalService,
        private toast: ToastService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
        this.datasetIdPropertyName = this.datasetIdPropertyName ?? 'id';

        this.dataset = this.stateDataset;

        let locales ={
            TEXT_ALL_SELECTED: 'All Selected',
            TEXT_CANCEL: 'Cancel',
            TEXT_CLEAR_ALL_FILTERS: 'Clear all Filters',
            TEXT_CLEAR_ALL_GROUPING: 'Clear all Grouping',
            TEXT_CLEAR_ALL_SORTING: 'Clear all Sorting',
            TEXT_COLLAPSE_ALL_GROUPS: 'Collapse all Groups',
            TEXT_CONTAINS: 'Contains',
            TEXT_COLUMNS: 'Columns',
            TEXT_COMMANDS: 'Commands',
            TEXT_COPY: 'Copy',
            TEXT_EQUALS: 'Equals',
            TEXT_ENDS_WITH: 'Ends With',
            TEXT_EXPAND_ALL_GROUPS: 'Expand all Groups',
            TEXT_EXPORT_TO_CSV: 'Export in CSV format',
            TEXT_EXPORT_TO_TEXT_FORMAT: 'Export in Text format (Tab delimited)',
            TEXT_EXPORT_TO_EXCEL: 'Export to Excel',
            TEXT_FORCE_FIT_COLUMNS: 'Force fit columns',
            TEXT_GROUP_BY: 'Group By',
            TEXT_HIDE_COLUMN: 'Hide Column',
            TEXT_ITEMS: 'элементов',
            TEXT_ITEMS_PER_PAGE: 'элементов на странице',
            TEXT_OF: 'из',
            TEXT_OK: 'OK',
            TEXT_LAST_UPDATE: 'Last Update',
            TEXT_PAGE: 'Страница',
            TEXT_REFRESH_DATASET: 'Refresh Dataset',
            TEXT_REMOVE_FILTER: 'Remove Filter',
            TEXT_REMOVE_SORT: 'Remove Sort',
            TEXT_SAVE: 'Save',
            TEXT_SELECT_ALL: 'Select All',
            TEXT_SYNCHRONOUS_RESIZE: 'Synchronous resize',
            TEXT_SORT_ASCENDING: 'Sort Ascending',
            TEXT_SORT_DESCENDING: 'Sort Descending',
            TEXT_STARTS_WITH: 'Starts With',
            TEXT_TOGGLE_FILTER_ROW: 'Toggle Filter Row',
            TEXT_TOGGLE_PRE_HEADER_ROW: 'Toggle Pre-Header Row',
            TEXT_X_OF_Y_SELECTED: '# of % selected',
        };

        this.gridOptions = {
            locales: locales,
            enablePagination: this.enablePagination,
            datasetIdPropertyName: this.datasetIdPropertyName,
            enableFiltering: this.enableFiltering, // false
            enableRowSelection: true,
            enableColumnReorder: false,
            enableGridMenu: false,
            enableHeaderMenu: this.enableHeaderMenu,
            enableContextMenu: false,
            enableColumnPicker: false,
            editable: true,
            alwaysShowVerticalScroll: false,
            autoHeight: true,
            enableFilterTrimWhiteSpace: true,
            headerMenu: {
                hideColumnHideCommand: true,
            },
            // enableEmptyDataWarningMessage: false,
            // headerRowHeight: 45,
            // rowHeight: 45, // increase row height so that the ng-select fits in the cell

            enableCellNavigation: true,
            enableCheckboxSelector: this.enableCheckboxSelector,
            checkboxSelector: {
                // remove the unnecessary "Select All" checkbox in header when in single selection mode
                hideSelectAllCheckbox: true,

                // you can override the logic for showing (or not) the expand icon
                // for example, display the expand icon only on every 2nd row
                // selectableOverride: (row: number, dataContext: any, grid: SlickGrid) => (dataContext.id % 2 === 1)
            },
            multiSelect: false,
            rowSelectionOptions: {
                // True (Single Selection), False (Multiple Selections)
                selectActiveRow: true,
            },

            pagination: {
                pageSizes: [20, 50, 100],
                pageSize: defaultPageSize,
                totalItems: 0,
            },
            presets: this.state != null ? this.state : { pagination: { pageNumber: 1, pageSize: 20 } },
            backendServiceApi:
                this.service == null
                    ? null
                    : {
                          service: new GridOdataService(),
                          options: {
                              enableCount: true,
                              version: 4,
                          } as OdataOption,
                          process: (query) => {
                              // return this.service.odata(query)
                              return new Promise((resolve, reject) => {
                                  resolve(this.genaratODataQuery(query));
                              });
                          },
                          postProcess: (response) => {
                              this.postProcess(response);
                          },
                      },
            params: {
                angularUtilService: this.angularUtilService, // provide the service to all at once (Editor, Filter, AsyncPostRender)
            },
            // enableAsyncPostRender: true, // for the Angular PostRenderer, don't forget to enable it
            // asyncPostRenderDelay: 0,    // also make sure to remove any delay to render it
            // editCommandHandler: (item, column, editCommand) => {
            //   this._commandQueue.push(editCommand);
            //   editCommand.execute();
            // },
        };
    }

    genaratODataQuery(query: string): Promise<any> | Observable<any> {
        // custom code for "select all" performance
        if (!this.angularGrid) {
            console.log('genaratODataQuery -> angularGrid is NULL');
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this.genaratODataQuery(query));
                }, constants.waitingGridColumnsFilterDataSourcesFetched);
            });
        }

        const multipleSelectColumns = this.columnDefinitions.filter((x) => x.filter && x.filter.model.name === Filters.multipleSelect.name);
        const filters = this.angularGrid.backendService.getCurrentFilters() as CurrentFilter[];
        const filterWithSelectAll = filters.filter((x) => multipleSelectColumns.find((c) => c.id === x.columnId && c.filter.collection?.length === x.searchTerms.length));

        if (filterWithSelectAll && filterWithSelectAll.length > 0) {
            const selectAllFilters = filters.filter((x) => !filterWithSelectAll.some((c) => c.columnId === x.columnId));
            this.angularGrid.backendService.updateFilters(selectAllFilters, true);
            const queryNew = this.angularGrid.backendService.buildQuery(this.angularGrid.backendService.options);

            filterWithSelectAll.forEach((x) => {
                selectAllFilters.push(x);
            });

            this.angularGrid.backendService.updateFilters(selectAllFilters, true);

            console.log(queryNew);
            return this.service.odata(queryNew).toPromise();
        }

        console.log(query);
        return this.service.odata(query).toPromise();
    }

    handleSelectedRowsChanged(e, args) {
        if (Array.isArray(args.rows) && this.angularGrid) {
            const selectedRow = args.rows.map((idx) => {
                return this.angularGrid.gridService.getDataItemByRowIndex(idx);
            });

            this.rowSelect.emit(selectedRow);
        }
    }

    refreshGrid() {
        this.angularGrid.filterService.updateFilters(this.angularGrid.filterService.getCurrentLocalFilters(), false, true);
    }

    refreshGridWithoutTriggers() {
        this.angularGrid.gridService.renderGrid();
    }

    angularGridReady(angularGrid: AngularGridInstance) {
        this.angularGrid = angularGrid;

        this.angularGrid.dataView.getItemMetadata = this.updateItemMetadata(this.angularGrid.dataView.getItemMetadata);
        this.angularGrid.slickGrid.invalidate();
        this.angularGrid.slickGrid.render();
    }

    updateItemMetadata(previousItemMetadata: any) {
        return (rowNumber: number) => {
            if (this.disabledRowRules) {
                // Get row
                const item = this.angularGrid.dataView.getItem(rowNumber);

                // Make sure row is defined (important if using the 'enableAddRow' option)
                if (item !== undefined) {
                    // Check if row is Dirty
                    for (const disabledRowRule of this.disabledRowRules) {
                        let applyCss: boolean = true;
                        for (const property of disabledRowRule.properties) {
                            if (item[property.property] !== property.value) {
                                applyCss = false;
                                break;
                            }
                        }

                        if (applyCss) {
                            return {
                                cssClasses: disabledRowRule.className || 'text-disabled',
                            };
                        }
                    }
                }
            }
        };
    }

    postProcess(data) {
        this.gridOptions.pagination.totalItems = data['@odata.count'];
        this.gridOptions = Object.assign({}, this.gridOptions);

        // once pagination totalItems is filled, we can update the dataset
        this.dataset = data['value'];

        setTimeout(() => {
            this.angularGrid.gridService.renderGrid();
        });
    }

    getDataset() {
        return this.angularGrid.dataView.getItems();
    }

    setDataset(data) {
        this.dataset = data;
    }

    isEmptyCell(e) {
        let isEmptyCell = false;

        e.target.classList.forEach((element) => {
            if (element === 'slick-cell') {
                isEmptyCell = true;
            }
        });

        return isEmptyCell;
    }

    onCellClicked(e, args) {
        this.onDblClicked = false;

        setTimeout(
            () => {
                if (this.onDblClicked) {
                    return;
                }

                const metadata = this.angularGrid.gridService.getColumnFromEventArguments(args);
                console.log(metadata);

                switch (metadata.columnDef.id) {
                    case 'edit': {
                        if (!this.isEmptyCell(e)) {
                            this.editItem(metadata.dataContext);
                        }

                        break;
                    }
                    case 'delete': {
                        if (!this.isEmptyCell(e)) {
                            const id = metadata.dataContext[this.datasetIdPropertyName];
                            this.deleteItem(id, metadata.dataContext);
                        }

                        break;
                    }
                    case 'actions': {
                        this.actionClick.emit({
                            targetId: e.target['id'],
                            data: metadata.dataContext,
                            colmunDef: metadata.columnDef,
                        });
                        break;
                    }                   
                }
            },
            0
        );
    }


    verifyCellIsEditableBeforeEditing(e, args) {
        // your logic here should return true/false if it's editable or not
        // args contains the dataContext and other Slickgrid arguments
        return false;
    }

    onCellValidationError(e, args) {
        this.toast.popup(args.validationResults.msg);

        // modalContent.onClose.subscribe(() => {
        //   // setTimeout(() => args.editor.focus());
        // });
    }

    addItemIntoGrid(model: any, isToBottom?: boolean): void {
        if (isToBottom !== true) {
            this.angularGrid.gridService.addItem(model);

            if (this.state && this.state.sorters) {
                this.angularGrid.sortService.updateSorting(this.state.sorters);
            }
        } else {
            this.angularGrid.gridService.addItem(model, { position: 'bottom', highlightRow: false });
        }
    }

    addItem() {
        const modal = this.bsModalService.show(this.formComponent, { class: 'modal-md-2' });
        const modalContent = modal.content as FormBaseComponent;
        modalContent.show(this.getDataset(), null, this.formData);
        modalContent.onSave.subscribe((model) => this.addItemIntoGrid(model));
    }

    editItem(item) {
        const modal = this.bsModalService.show(this.formComponent, { class: 'modal-md-2' });
        const modalContent = modal.content as FormBaseComponent;
        const id = item[this.datasetIdPropertyName];
        modalContent.show(this.getDataset(), item, this.formData);
        modalContent.onSave.subscribe((savedItem) => {
            this.angularGrid.gridService.updateItemById(id, savedItem);
            this.angularGrid.gridService.renderGrid();
        });
    }

    deleteItem(id: any, model: any) {
        const modal = this.bsModalService.show(ConfirmationModalComponent);
        const modalContent = modal.content as ConfirmationModalComponent;
        modalContent.show('Удалить', 'Вы уверены?');

        modalContent.onClose.subscribe((result) => {
            if (result === true) {
                // when pressed Yes
                if (id) {
                    this.angularGrid.gridService.deleteItemById(id);
                }

                this.rowDelete.emit(model);
            } else if (result === false) {
                // when pressed No
            } else {
                // When closing the modal without no or yes
            }
        });
    }

    showSpinner() {
        this.spinnerService.isLoading.next(true);
    }

    hideSpinner() {
        this.spinnerService.isLoading.next(false);
    }
}
