import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { Observable } from 'rxjs';
import { IAdditionalField } from 'src/app/models/additionalField.model';

@Injectable({
    providedIn: 'root',
})
export class AdditionalFieldsService extends DataService<IAdditionalField> {
    
    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }

    get(id: number): Observable<IAdditionalField> {
        return this.http.get<IAdditionalField>(`${this.configService.apiURI}/api/additionalFields/${id}`);
    }

    getForEntity(type: string): Observable<IAdditionalField[]> {
        return this.http.get<IAdditionalField[]>(`${this.configService.apiURI}/api/additionalFields/entityType/${type}`);
    }

    getForEntityGrid(type: string): Observable<IAdditionalField[]> {
        return this.http.get<IAdditionalField[]>(`${this.configService.apiURI}/api/additionalFields/entityType/${type}/grid`);
    }

    update(id: number, data: any): Observable<IAdditionalField> {
        return this.http.put<IAdditionalField>(`${this.configService.apiURI}/api/additionalFields/${id}`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/additionalFields/${id}`);
    }

    add(data: any): Observable<IAdditionalField> {
        return this.http.post<IAdditionalField>(`${this.configService.apiURI}/api/additionalFields/`, data);
    }

    odata(query: string): Observable<any> {
        return this.http.post(
            `${this.configService.apiURI}/odata/additionalFields`,
            { query }
        );
    }
}
