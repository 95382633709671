import { IEnum } from './../../models/enum.model';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { DropDownFormControl } from '../form/controls/dropdown-form-control/dropdown-form-control';

function getDataUrl(data: number[], contentType: string) {
    if (!data || !contentType) {
        return '';
    }

    if (data instanceof Array) {
        let binary = '';
        const bytes = new Uint8Array(data);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const urlContent = window.btoa(binary);

        return 'data:' + contentType + ';base64,' + urlContent;
    }

    return 'data:' + contentType + ';base64,' + data;
}

function getBase64(data: number[]) {
    if (!data) {
        return '';
    }

    if (data instanceof Array) {
        let binary = '';
        const bytes = new Uint8Array(data);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const urlContent = window.btoa(binary);

        return urlContent;
    }

    return data + '';
}

function replaceFilterQuery(query: string, propertyName: string, queryProperty: string): string {
    const matchesEq = query.match(new RegExp(`${propertyName} eq ['%a-zA-Z0-9]+`, 'g'));

    if (matchesEq) {
        matchesEq.forEach((match) => {
            query = query.replace(match, match.replace(propertyName, `${propertyName}/any(d: d/${queryProperty}`) + ')');
        });
    } else if (query.indexOf(propertyName) !== -1) {
        query = query.replace(propertyName, `${propertyName}/any(d: d/${queryProperty}`) + ')';
    }

    return query;
}

function replaceFilterQueryForOneInheritedItem(query: string, propertyName: string, fieldName: string = 'name'): string {
    const matchesEq = query.match(new RegExp(`${propertyName} eq ['%a-zA-Z0-9]+`, 'g'));

    if (matchesEq) {
        matchesEq.forEach((match) => {
            query = query.replace(match, match.replace(propertyName, `${propertyName}/${fieldName}`));
        });
    } else if (query.indexOf(propertyName) !== -1) {
        query = query.replace(propertyName, `${propertyName}/${fieldName}`);
    }

    return query;
}

function replaceOrderByQuery(query: string, propertyName: string, queryProperty: string): string {
    query = query.replace('$orderby', 'orderby');

    query = query.replace(propertyName, `${propertyName}[0]/${queryProperty}`);
    return query;
}

function getAddressString(dataContext: any) {
    if (!dataContext) {
        return '';
    }

    let address = dataContext.address ? dataContext.address : '';
    address += dataContext.city ? (address.length > 0 ? `, ${dataContext.city}` : `${dataContext.city}`) : '';
    address += dataContext.state ? (address.length > 0 ? `, ${dataContext.state}` : `${dataContext.state}`) : '';
    address += dataContext.zipCode ? (address.length > 0 ? `, ${dataContext.zipCode}` : `${dataContext.zipCode}`) : '';

    return address;
}

function range(start, end): number[] {
    const list = [];
    for (let i = start; i <= end; i++) {
        list.push(i);
    }
    return list;
}

function parseIEnum(value: string, items: IEnum[]) {
    const item = items.filter((filterItem) => {
        return filterItem.name === value;
    });

    return item && item[0] ? item[0].description : '';
}

function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
}

function formatDate(date: string): string {
    if (date) {
        return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
    }

    return null;
}

function formatDateTime(date: string): string {
    if (date) {
        return moment(date).seconds(0).milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
    }

    return null;
}

function getRangeAndSetControlCollection(start: number, end: number, form: FormGroup, controlName: any): void {
    const control = form.get(controlName) as DropDownFormControl;
    const collection = [];

    for (let i = start; i <= end; i++) {
        collection.push({ name: i, description: i.toString() });
    }

    control.setCollection(collection);
}

export {
    getDataUrl,
    getBase64,
    replaceFilterQuery,
    replaceOrderByQuery,
    replaceFilterQueryForOneInheritedItem,
    getAddressString,
    range,
    parseIEnum,
    getDifferenceInDays,
    formatDate,
    formatDateTime,
    getRangeAndSetControlCollection,
};
