<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            [matDatepicker]="picker"
            [disabled]="control.disabled"
            class="form-control form-control-sm mat-input-element mat-form-field-autofill-control"
            (dateChange)="changeDate($event)"
            [min]="minDate"
            [max]="maxDate"
            [formControlName]="controlName"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker (opened)="open()"></mat-datepicker>
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.matDatepickerMin">Минимальная дата - ошибка</span>
            <span *ngIf="control.errors?.matDatepickerMax">Максимальная дата - ошибка</span>
        </mat-error>
    </mat-form-field>
</div>
