import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { EmployeeListComponent } from './employees/employee-list/employee-list.component';
import { SharedModule } from '../shared/shared.module';
import { FormModule } from '../shared/form/form.module';
import { RouterModule } from '@angular/router';
import { PupilListComponent } from './pupils/pupil-list/pupil-list.component';
import { ParentListComponent } from './parents/parent-list/parent-list.component';
import { EmployeeInformationComponent } from './employees/employee-information/employee-information.component';
import { PupilInformationComponent } from './pupils/pupil-information/pupil-information.component';
import { ParentInformationComponent } from './parents/parent-information/parent-information.component';
import { GroupListComponent } from './groups/group-list/group-list.component';
import { GroupInformationComponent } from './groups/group-information/group-information.component';
import { CourseListComponent } from './courses/course-list/course-list.component';
import { CourseInformationComponent } from './courses/course-information/course-information.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdditionalFieldInformationComponent } from './additionalField/additionalField-information/additionalField-information.component';
import { AdditionalFieldListComponent } from './additionalField/additionalField-list/additionalField-list.component';
import { JobCategoryFormModalComponent } from './employees/employee-information/modals/jobCategory-form-modal/jobCategory-form-modal.component';
import { ChildFormModalComponent } from './employees/employee-information/modals/child-form-modal/child-form-modal.component';
import { EducationFormModalComponent } from './employees/employee-information/modals/education-form-modal/education-form-modal.component';
import { TrainingFormModalComponent } from './employees/employee-information/modals/training-form-modal/training-form-modal.component';
import { PupilFormModalComponent } from './courses/course-information/modals/pupil-form-modal/pupil-form-modal.component';
import { GroupPupilFormModalComponent } from './groups/group-information/modals/pupil-form-modal/group-pupil-form-modal.component';
import { GroupEmployeeFormModalComponent } from './groups/group-information/modals/employee-form-modal/group-employee-form-modal.component';
import { PupilParentFormModalComponent } from './pupils/pupil-information/modals/parent-form-modal/pupil-parent-form-modal.component';
import { PupilCourseFormModalComponent } from './pupils/pupil-information/modals/course-form-modal/pupil-course-form-modal.component';
import { ParentPupilFormModalComponent } from './parents/parent-information/modals/pupil-form-modal/parent-pupil-form-modal.component';
import { InnovationActivityFormModalComponent } from './employees/employee-information/modals/innovationActivity-form-modal/innovationActivity-form-modal.component';
import { PublicOrganizationFormModalComponent } from './employees/employee-information/modals/publicOrganization-form-modal/publicOrganization-form-modal.component';

@NgModule({
  declarations: [
    LoginComponent,
    EmployeeListComponent,
    EmployeeInformationComponent,
    PupilListComponent,
    PupilInformationComponent,
    ParentListComponent,
    ParentInformationComponent,
    GroupListComponent,
    GroupInformationComponent,
    CourseListComponent,
    CourseInformationComponent,
    DashboardComponent,
    AdditionalFieldListComponent,
    AdditionalFieldInformationComponent,

    JobCategoryFormModalComponent,
    ChildFormModalComponent,
    EducationFormModalComponent,
    TrainingFormModalComponent,
    InnovationActivityFormModalComponent,
    PublicOrganizationFormModalComponent,

    PupilFormModalComponent,
    
    GroupPupilFormModalComponent,
    GroupEmployeeFormModalComponent,

    PupilParentFormModalComponent,
    PupilCourseFormModalComponent,

    ParentPupilFormModalComponent
  ],
  exports: [
    LoginComponent,
    EmployeeListComponent,
    EmployeeInformationComponent,
    PupilListComponent,
    PupilInformationComponent,
    ParentListComponent,
    ParentInformationComponent,
    GroupListComponent,
    GroupInformationComponent,
    CourseListComponent,
    CourseInformationComponent,
    DashboardComponent,
    AdditionalFieldListComponent,
    AdditionalFieldInformationComponent,

    JobCategoryFormModalComponent,
    ChildFormModalComponent,
    EducationFormModalComponent,
    TrainingFormModalComponent,
    InnovationActivityFormModalComponent,
    PublicOrganizationFormModalComponent,
    
    PupilFormModalComponent,

    GroupPupilFormModalComponent,
    GroupEmployeeFormModalComponent,

    PupilParentFormModalComponent,
    PupilCourseFormModalComponent,

    ParentPupilFormModalComponent
],
  imports: [
    CommonModule,
    SharedModule,
    FormModule,
    RouterModule,
  ],
})
export class PagesModule {}
