import { Formatter } from 'angular-slickgrid';
import { accountStatus } from '../../classiffiers/accountStatus';

export class CustomFormatter {
    static statusFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return value ? `Aктивный` : `Неактивный`;
    };

    static additionalFieldFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        if (dataContext.additionalFields) {
            const additionalFieldId = columnDef.field.split('.')[1];
            const item = dataContext.additionalFields.find(x => x.additionalFieldId == additionalFieldId);
            if (item) {
                return item?.value;
            }
        }

        return '';
    };

    static additionalFieldInGridFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return value ? `Да` : `Нет`;
    };

    static accountStatusFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        switch (value) {
            case accountStatus.open: {
                return 'Open';
            }
            case accountStatus.draft: {
                return 'Draft';
            }
            case accountStatus.pending: {
                return 'Pending';
            }
            case accountStatus.converted: {
                return 'Converted';
            }
            case accountStatus.denied: {
                return 'Denied';
            }
            case accountStatus.paidOut: {
                return 'Paid Out';
            }
            case accountStatus.chargedOff: {
                return 'Charged Off';
            }
            case accountStatus.previouslyApproved: {
                return 'Previously Approved';
            }
            default: {
                return value;
            }
        }
    };

    static getListFormatterResult = (arr: any[], shownItemsCount: number = 1) => {
        if (arr != null && arr.length === 0) {
            return '';
        }

        let result = `<span>${arr[0]}</span>`;

        const initCount = arr.length < shownItemsCount ? arr.length : shownItemsCount;
        for (let i = 1; i < initCount; i++) {
            result = result + `, <span>${arr[i]}</span>`;
        }

        if (arr.length > shownItemsCount) {
            const tooltipValues: any[] = arr.slice(shownItemsCount);

            result = result + `<span> + <a title="${tooltipValues.join(', ')}">${tooltipValues.length} More</a></span>`;
        }

        return result;
    };

    static loanAgeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        let backgroundColor = '';

        switch (value) {
            case 'Current':
                backgroundColor = '#00CC00';
                break;
            case '1 Month':
                backgroundColor = '#FCBA03';
                break;
            case '2 Months':
                backgroundColor = '#E70716';
                break;
            case '3 Months':
                backgroundColor = '#B7000D';
                break;
            case '4 Months':
                backgroundColor = '#720028';
                break;
            case '>4 Months':
                backgroundColor = '#480005';
                break;
            default:
                return ``;
        }

        return `<span style="padding: 3px;color: #fff;background-color: ${backgroundColor};">${value}</span>`;
    };
}
