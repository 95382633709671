import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/employee.model';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { Location } from '@angular/common';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { Column, FieldType, Filters, Formatter, Formatters, GridState, OnEventArgs, OperatorType } from 'angular-slickgrid';
import { GroupsService } from '../../groups/groups.service';
import { IGroup } from 'src/app/models/group.model';
import { PupilsService } from '../../pupils/pupils.service';
import { linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { IEnum } from 'src/app/models/enum.model';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { IPupil } from 'src/app/models/pupil.model';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { GroupPupilFormModalComponent } from './modals/pupil-form-modal/group-pupil-form-modal.component';
import { GroupEmployeeFormModalComponent } from './modals/employee-form-modal/group-employee-form-modal.component';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';

@Component({
    selector: 'app-group-information',
    templateUrl: './group-information.component.html',
    styleUrls: ['./group-information.component.scss'],
})
export class GroupInformationComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('employeesGrid') employeesGrid: GridComponent;
    @ViewChild('pupilsGrid') pupilsGrid: GridComponent;

    public isGridLoaded: boolean = false;

    public groupForm: FormGroup = new FormGroup({
        id: new NumberFormControl(false, true),
       
        number: new NumberFormControl(true),
        type: new DropDownFormControl(true, { keyPropertyName: 'name', valuePropertyName: 'description' }),
    });

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    subTitle: string = 'Группа';

    employeesColumnDefinitions: Column[];
    pupilsColumnDefinitions: Column[];

    pupils: IPupil[] = [];
    public pupilsFormModalComponent: any = GroupPupilFormModalComponent;

    employees: IEmployee[] = [];
    public employeesFormModalComponent: any = GroupEmployeeFormModalComponent;

    genderTypes: IEnum[] = [];
    groupTypes: IEnum[] = [];

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        public pupilsService: PupilsService,
        private knowledgeBaseService: KnowledgeBaseService,
        public groupsService: GroupsService,
        private toast: ToastService,
        public location: Location
    ) {
        super(null, true, true, router);
        
        this.subscriptionHandler.subscriptions = knowledgeBaseService.genderTypesSource$.subscribe((model: IEnum[]) => {
            this.genderTypes = model;
        });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.groupTypeSource$.subscribe((model: IEnum[]) => {
            this.groupTypes = model;
            const control = this.groupForm.get('type') as DropDownFormControl;
            control.setCollection(model);
        });
        
        this.init();
    }

    init(): void {
        if (this.isCreationMode) {
            this.title = 'Создание группы';
            this.isGridLoaded = true;
        } else {
            this.subscriptionHandler.subscriptions = this.groupsService.get(this.getIdFromUrl()).subscribe((model: IGroup) => {
                this.updateForm(model);              
            });
        }

        this.employeesColumnDefinitions = [                 
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.employeeLastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];

        this.pupilsColumnDefinitions = [                 
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.pupilLastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Gender',
                name: 'Пол',
                field: 'gender',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.genderTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.genderTypesSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'delete',
                field: 'id',
                formatter: Formatters.deleteIcon,
                minWidth: 30,
                maxWidth: 30,
                cssClass: 'text-body',
            },
        ];
    }

    public addPupilItem(): void {
        this.pupilsGrid.addItem();
    }

    public addEmployeeItem(): void {
        this.employeesGrid.addItem();
    }

    save(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
            if (!this.groupForm.valid) {
                this.toast.required();

                markFormGroupTouched(this.groupForm);

                resolve(false);

                return;
            }

            this.updateDataset();

            this.saveGroup(resolve);
        });

        return promise;
    }

    delete(): void | Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
           
            const id = this.getIdFromUrl();
            if (id) {
                this.groupsService
                .delete(id)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: any) => {
                    resolve(true);
                    this.toast.success(`Группа`, 'Удалена');
                });
            }
        });

        return promise;
    }
    
    private updateDataset() {
        this.pupils = this.pupilsGrid.getDataset();
        this.employees = this.employeesGrid.getDataset();
    }

    saveGroup(resolve): void {
        const group = this.groupForm.getRawValue() as IGroup;
        group.pupils = this.pupils;
        group.employees = this.employees;

        if (!group.id) {
            this.groupsService
                .add(group)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: IGroup) => {
                    resolve(true);
                    this.updateNavigation(model);
                    this.toast.success(`Группа`, 'Добавлена');
                });

            return;
        }

        this.groupsService
            .update(group.id, group)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: IGroup) => {
                resolve(true);
                this.updateNavigation(model);
                this.toast.success(`Группа`, 'Изменена');
            });
    }

    get isCreationMode(): boolean {
        return this.activeRoute.snapshot.paramMap.get('id') === 'create';
    }

    private updateNavigation(model: IGroup) {
        this.updateForm(model);
        this.url = `/groups/${model.id}`;
    }

    private updateForm(model: IGroup) {
        this.title = `(Г) ${model.number} - ${this.groupTypes.find(i => i.name === model.type).description}`;
        this.groupForm.patchValue(model);

        this.pupils = model.pupils;
        this.employees = model.employees;

        this.isGridLoaded = true;
    }

    private getIdFromUrl(): number {
        return Number(this.activeRoute.snapshot.paramMap.get('id'));
    }

    private employeeLastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IEmployee;
       
        this.router.navigate([`/employees/${model.id}`]);
    }

    private pupilLastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IEmployee;
       
        this.router.navigate([`/pupils/${model.id}`]);
    }

    private genderTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.genderTypes.find(i => i.name === value).description;
    };

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
