import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/infrastructure/authentication/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    username: string;
    password: string;

    constructor(private authService: AuthService) {}

    login() {
        this.authService.login({password: this.password, username: this.username });
    }

    ngOnInit() {}
}
