import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { constants } from 'src/app/shared/constants/constants';

@Component({
    selector: 'app-grid-search',
    templateUrl: './grid-search.component.html',
    styleUrls: ['./grid-search.component.scss'],
})
export class GridSearchComponent implements OnInit {
    @Input() state: string | null;
    @Output() onChange: EventEmitter<string> = new EventEmitter();
    searchValue = '';
    timeOut;

    constructor() {}

    ngOnInit(): void {
        if (this.state) {
            this.searchValue = this.state;
            this.updateFilter(this.state);
        }
    }

    updateFilter(text) {
        clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
            this.onChange.emit(text);
        }, constants.grid.commonSearchTimeOut);
    }
}
