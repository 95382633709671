import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export abstract class BaseTabPageComponent {
    titleSource = new BehaviorSubject<string>('');
    title$ = this.titleSource.asObservable();

    private urlSource = new BehaviorSubject<any>({ prev: '', next: '' });
    url$ = this.urlSource.asObservable();

    private tabTitle: string;
    private tabUrl: string;
    private saveDisabled: boolean = false;
    private deleteDisabled: boolean = false;

    deleteEnabled: boolean = false;

    get title(): string {
        return this.tabTitle;
    }
    set title(value: string) {
        this.tabTitle = value;
        this.titleSource.next(this.tabTitle);
    }

    get url(): string {
        return this.tabUrl;
    }
    set url(value: string) {
        const prev = this.tabUrl;
        this.tabUrl = value;
        this.urlSource.next({ prev, next: this.tabUrl });
    }

    get isSaveEnabled(): boolean {
        return this.saveEnabled;
    }

    get isSaveDisabled(): boolean {
        return this.saveDisabled;
    }

    get isDeleteEnabled(): boolean {
        return this.deleteEnabled;
    }

    get isDeleteDisabled(): boolean {
        return this.deleteDisabled;
    }

    public setSaveDisabled(value: boolean) {
        this.saveDisabled = value;
    }

    public setDeleteDisabled(value: boolean) {
        this.deleteDisabled = value;
    }

    get isBasePage(): boolean {
        return this.basePage;
    }

    constructor(title: string, private saveEnabled: boolean, deleteEnabled: boolean, router: Router, private basePage: boolean = true) {
        this.title = title;
        this.url = router.url;
        this.deleteEnabled = deleteEnabled;

        router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    exit(): void {} 

    abstract save(): Promise<boolean> | void;
    abstract delete(): Promise<boolean> | void;
}
