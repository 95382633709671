import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { Observable } from 'rxjs';
import { IGroup } from 'src/app/models/group.model';
import { IGroupShort } from 'src/app/models/group-short.model';

@Injectable({
    providedIn: 'root',
})
export class GroupsService extends DataService<IGroup> {
    
    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }

    get(id: number): Observable<IGroup> {
        return this.http.get<IGroup>(`${this.configService.apiURI}/api/groups/${id}`);
    }

    short(): Observable<IGroupShort[]> {
        return this.http.get<IGroupShort[]>(`${this.configService.apiURI}/api/groups/short`);
    }

    update(id: number, data: any): Observable<IGroup> {
        return this.http.put<IGroup>(`${this.configService.apiURI}/api/groups/${id}`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/groups/${id}`);
    }

    add(data: any): Observable<IGroup> {
        return this.http.post<IGroup>(`${this.configService.apiURI}/api/groups/`, data);
    }

    odata(query: string): Observable<any> {
        return this.http.post(
            `${this.configService.apiURI}/odata/groups`,
            { query }
        );
    }
}
