import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Column, FieldType, OnEventArgs } from 'angular-slickgrid';
import { IGroup } from 'src/app/models/group.model';
import { linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DisabledRowRule } from 'src/app/shared/components/grid/models/disabled-row-rule';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { CoursesService } from '../courses.service';

@Component({
    selector: 'app-courses',
    templateUrl: './course-list.component.html',
    styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent extends BaseTabPageComponent implements OnDestroy {
    @ViewChild('gridCourseList') grid: GridComponent;

    subTitle: string = '';

    disabledRowRules: DisabledRowRule[];
    columnDefinitions: Column[];
        
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(
        private router: Router,
        public coursesService: CoursesService,
    ) {
        super('Кружки', false, false, router);
        
        this.init();
    }

    get createLinkUrl(): string {
        return `/courses/create`;
    }

    init(): void {
        this.columnDefinitions = [
            {
                id: 'Name',
                name: 'Название',
                field: 'name',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.nameClick.bind(this),
            },
        ];
    }

    private nameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IGroup;
       
        this.router.navigate([`/courses/${model.id}`]);
    }

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
