import { OperatorType } from 'angular-slickgrid';

export const constants = {
    waitingGridColumnsFilterDataSourcesFetched: 50, // ms
    shortDateFormat: 'DD-MM-YYYY',
    loanRegisterTypes: {
        today: { name: 'Today' },
        date: { name: 'Date' },
        dateRange: { name: 'Date Range' },
    },
    grid: {
        commonSearchTimeOut: 500,
        employees: {
            commonSearchFields: [
                { id: 'FullName', name: 'Name', field: 'fullName' },
                { id: 'UserName', name: 'Username', field: 'userName' },
            ],
        },
        divisions: {
            commonSearchFields: [{ id: 'Name', name: 'Name', field: 'name' }],
        },
        states: {
            commonSearchFields: [
                {
                    id: 'StateDescription',
                    name: 'StateDescription',
                    field: 'stateDescription',
                },
            ],
        },
        accounts: {
            commonSearchFields: {
                fullName: { operator: OperatorType.startsWith },
                fullAddress: {},
                accountNumber: {},
                ssn: { operator: OperatorType.endsWith },
                phone: {},
            },
        },
        applications: {
            commonSearchFields: {
                ssn: { name: 'ssn', operator: OperatorType.endsWith },
                firstName: {
                    name: 'firstName',
                    operator: OperatorType.startsWith,
                },
                lastName: { name: 'lastName', operator: OperatorType.startsWith },
                customer: { name: 'customer', operator: OperatorType.contains },
                startDate: {
                    id: 'createdOn',
                    name: 'startDate',
                    field: 'createdOn',
                    operator: OperatorType.greaterThan,
                },
                endDate: {
                    id: 'createdOn',
                    name: 'endDate',
                    field: 'createdOn',
                    operator: OperatorType.lessThan,
                },
            },
        },
        branches: {
            commonSearchFields: [{ id: 'Name', name: 'Name', field: 'name' }],
        },
        loanRegister: {
            commonSearchFields: {
                type: {
                    id: 'Type',
                    name: 'type',
                    field: 'type',
                    operator: OperatorType.equal,
                },
                startDate: {
                    id: 'CreatedOn',
                    name: 'startDate',
                    field: 'createdOn',
                    operator: OperatorType.greaterThan,
                },
                endDate: {
                    id: 'CreatedOn',
                    name: 'endDate',
                    field: 'createdOn',
                    operator: OperatorType.lessThan,
                },
                userId: {
                    id: 'UserId',
                    name: 'userId',
                    field: 'userId',
                    operator: OperatorType.equal,
                },
                datesArray: {
                    id: 'datesArray',
                    name: 'datesArray',
                    field: 'datesArray',
                    operator: OperatorType.in,
                },
                ledgerDate: {
                    id: 'LedgerDate',
                    name: 'ledgerDate',
                    field: 'ledgerDate',
                    operator: OperatorType.equal,
                },
            },
        },
        ecoas: {
            commonSearchFields: {
                firstName: {
                    id: 'FirstName',
                    name: 'FirstName',
                    field: 'firstName',
                    operator: OperatorType.startsWith,
                },
                lastName: {
                    id: 'LastName',
                    name: 'LastName',
                    field: 'lastName',
                    operator: OperatorType.startsWith,
                },
                ssn: { id: 'Ssn', name: 'Ssn', field: 'ssn', operator: OperatorType.endsWith },
                startDate: {
                    id: 'CreatedOn',
                    name: 'startDate',
                    field: 'createdOn',
                    operator: OperatorType.greaterThan,
                },
                endDate: {
                    id: 'CreatedOn',
                    name: 'endDate',
                    field: 'createdOn',
                    operator: OperatorType.lessThan,
                },
                denial: {
                    id: 'Type',
                    name: 'Denial',
                    field: 'Type',
                    operator: OperatorType.equal,
                },
                approveForCurrent: {
                    id: 'Type',
                    name: 'ApproveForCurrent',
                    field: 'Type',
                    operator: OperatorType.equal,
                },
                approveForNew: {
                    id: 'Type',
                    name: 'ApproveForNew',
                    field: 'Type',
                    operator: OperatorType.equal,
                },
            },
        },
    },
};
