export const accountStatus = {
    open: 'Open',
    draft: 'Draft',
    pending: 'Pending',
    previouslyApproved: 'PreviouslyApproved',
    converted: 'Converted',
    denied: 'Denied',
    paidOut: 'PaidOut',
    paidOutDescription: 'Paid Out',
    chargedOff: 'ChargedOff',
    chargedOffDescription: 'Charged Off',
};
