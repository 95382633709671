<div class="form-group inline small mat-combobox" [class.required]="isRequired" [class.disabled]="isDisabled" [formGroup]="parentForm">
    <mat-label *ngIf="title" class="pr-2">{{ title }}</mat-label>
    <mat-radio-group [formControlName]="controlName">
        <mat-radio-button *ngFor="let item of collection; let i = index" value="{{ item[keyPropertyName] || item }}">
            {{ item[valuePropertyName] || item }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-error>
        <span *ngIf="control.errors?.required">*Обязательно</span>
    </mat-error>
</div>
