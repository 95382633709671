<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            currencyMask
            [class.is-invalid]="control.invalid && control.touched"
            [options]="options"
            type="text"
            [attr.maxlength]="maxLength"
            class="form-control form-control-sm"
            [formControlName]="controlName"
        />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.minlength">Минимальное значение должно быть {{ control.minLength }}</span>
        </mat-error>
    </mat-form-field>
</div>
