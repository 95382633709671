import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseTabPageComponent implements OnDestroy {

    subTitle: string = '';

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    constructor(
        private router: Router,
    ) {
        super('Главная страница', false, false, router);
    }


    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
