<div>
    <angular-slickgrid
        gridId="{{ id }}"
        [columnDefinitions]="columnDefinitions"
        [gridOptions]="gridOptions"
        [dataset]="dataset"
        (sgOnClick)="onCellClicked($event.detail.eventData, $event.detail.args)"
        (onAngularGridCreated)="angularGridReady($event)"
        (sgOnValidationError)="onCellValidationError($event.detail.eventData, $event.detail.args)"
        (sgOnBeforeEditCell)="verifyCellIsEditableBeforeEditing($event.detail.eventData, $event.detail.args)"
        (sgOnSelectedRowsChanged)="handleSelectedRowsChanged($event.detail.eventData, $event.detail.args)"
    ></angular-slickgrid>
</div>
