import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { Observable } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';

@Injectable({
    providedIn: 'root',
})
export class EmployeesService extends DataService<IEmployee> {
    
    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }

    get(id: number): Observable<IEmployee> {
        return this.http.get<IEmployee>(`${this.configService.apiURI}/api/employees/${id}`);
    }

    getforGroup(): Observable<IEmployee[]> {
        return this.http.get<IEmployee[]>(`${this.configService.apiURI}/api/employees/forGroup`);
    }

    update(id: number, data: IEmployee): Observable<IEmployee> {
        return this.http.put<IEmployee>(`${this.configService.apiURI}/api/employees/${id}`, data);
    }

    add(data: IEmployee): Observable<IEmployee> {
        return this.http.post<IEmployee>(`${this.configService.apiURI}/api/employees/`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/employees/${id}`);
    }

    odata(query: string): Observable<any> {
       // query = this.formatQuery(query);
        return this.http.post(
            `${this.configService.apiURI}/odata/employees?$expand=additionalFields`,
            { query }
        );
    }

    formatQuery(query: string): string {
        const filterQuery = this.getQueryBy('filter', query);

        if (filterQuery) {

            // const matchesEq = query.match(new RegExp(`/(contains\(AdditionalField.)\d+(\, ').+('\))`, 'g'));

            // AdditionalFields/any(x: x/AdditionalFieldId eq 12 and contains(x/Value, 'ANY VALUE'))

            // let replacedFilterQuery = replaceFilterQuery(filterQuery, 'Pupils', 'Id');

            // query = query.replace(filterQuery, replacedFilterQuery);
            // console.log(query);
        }

        return query;
    }
}