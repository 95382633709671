import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IParent } from 'src/app/models/parent.model';
import { Column, FieldType, Filters, Formatter, OnEventArgs } from 'angular-slickgrid';
import { CustomFormatter } from 'src/app/shared/components/grid/formatters';
import { dateFormatter, linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DisabledRowRule } from 'src/app/shared/components/grid/models/disabled-row-rule';
import { ParentsService } from '../parents.service';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { IEnum } from 'src/app/models/enum.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';

@Component({
    selector: 'app-parents',
    templateUrl: './parent-list.component.html',
    styleUrls: ['./parent-list.component.scss'],
})
export class ParentListComponent extends BaseTabPageComponent {
    @ViewChild('gridParentsList') grid: GridComponent;

    subTitle: string = '';
    disabledRowRules: DisabledRowRule[];
    columnDefinitions: Column[];

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  
    parentTypes: IEnum[] = [];

    constructor(
        private router: Router,
        private additionalFieldsService: AdditionalFieldsService,
        public parentsService: ParentsService,
        private knowledgeBaseService: KnowledgeBaseService,
    ) { 
        super('Родители', false, false, router);
        
        this.subscriptionHandler.subscriptions = knowledgeBaseService.parentType$.subscribe((model: IEnum[]) => {
            this.parentTypes = model;
        });

        this.subscriptionHandler.subscriptions = additionalFieldsService.getForEntityGrid('Parent').subscribe((model: IAdditionalField[]) => {
            this.initState();
            this.addAdditionalColumnDefinitions(model);
        });
    }

    initState(): void {
        this.disabledRowRules = [
            {
                properties: [{ property: 'isActive', value: false }],
            },
        ];

        this.columnDefinitions = [
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.lastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Type',
                name: 'Тип',
                field: 'type',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.parentTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.parentType$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'DateOfBirth',
                name: 'Дата Рождения',
                field: 'dateOfBirth',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'Job',
                name: 'Место работы',
                field: 'job',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'JobPosition',
                name: 'Должность',
                field: 'jobPosition',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'Phone',
                name: 'Телефон',
                field: 'phone',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'IsActive',
                name: 'Активный',
                field: 'isActive',
                type: FieldType.boolean,
                sortable: true,
                filterable: true,
                filter: {
                    collection: [
                        { isActive: '', label: 'Выбрать все' },
                        { isActive: true, label: 'Активный' },
                        { isActive: false, label: 'Неактивный' },
                    ],
                    customStructure: {
                        value: 'isActive',
                        label: 'label',
                    },
                    model: Filters.singleSelect,
                },
                formatter: CustomFormatter.statusFormatter,
            }
        ];
    }
    
    addAdditionalColumnDefinitions(additionalFields: IAdditionalField[]): void {
        if (additionalFields) {
            additionalFields.forEach(x => {
                this.columnDefinitions.push({
                    id: `AdditionalField.${x.id}`,
                    name: x.name,
                    field: `additionalField.${x.id}`,
                    type: FieldType.string,
                    formatter: CustomFormatter.additionalFieldFormatter,
                    sortable: false,
                    filterable: false,
                });
            });
        }
    }
    
    get createLinkUrl(): string {
        return `/parents/create`;
    }

    private lastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IParent;
        this.router.navigate([`/parents/${model.id}`]);
    }

    private parentTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.parentTypes.find(i => i.name === value).description;
    };

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    
    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
