import { Component, Input } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { SsnFormControl } from './ssn-form-control';

@Component({
    selector: 'app-ssn-form-control',
    templateUrl: './ssn-form-control.component.html',
})
export class SsnFormControlComponent extends AbstractFormControlComponent<SsnFormControl> {
    patterns = { 0: { pattern: new RegExp('\\d') }, X: { pattern: new RegExp('\\d'), symbol: 'X' } };

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
