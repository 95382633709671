<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <input matInput type="text" mask="000 000999999" [class.is-invalid]="control.invalid && control.touched" class="form-control form-control-sm" [formControlName]="controlName" placeholder="( )" />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.mask">Формат: (XXX) XXXXXXX or (XXX) XXXXXX</span>
        </mat-error>
    </mat-form-field>
</div>
