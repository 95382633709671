import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IEnum } from 'src/app/models/enum.model';
import { IParent } from 'src/app/models/parent.model';
import { ParentsService } from 'src/app/pages/parents/parents.service';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { FormBaseComponent } from 'src/app/shared/components/grid/modals/form-base.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { cleanObject } from 'src/app/shared/form/extensions';

@Component({
    selector: 'app-pupil-parent-form-modal',
    templateUrl: './pupil-parent-form-modal.component.html',
    styleUrls: ['./pupil-parent-form-modal.component.scss'],
})
export class PupilParentFormModalComponent extends FormBaseComponent implements OnDestroy {
    
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
    
    form = new FormGroup({
        parent: new DropDownFormControl(true, { keyPropertyName: 'value', valuePropertyName: 'name' }),
    });

    parents: IParent[] = [];
    parentTypes: IEnum[] = [];

    public isFormDisabled: boolean = false;

    constructor(public parentsService: ParentsService,
        private knowledgeBaseService: KnowledgeBaseService,
        private bsModalRef: BsModalRef) {
        super(bsModalRef);   
        
        this.subscriptionHandler.subscriptions = knowledgeBaseService.parentType$.subscribe((model: IEnum[]) => {
            this.parentTypes = model;
        });
    }

    public show(collection: IParent[], selectedItem: IParent, id: number) {
        this.subscriptionHandler.subscriptions = this.parentsService.getforPupil().subscribe((model: IParent[]) => {
           
            this.parents = model.filter((b) => !collection.find((c) => c.id === b.id) || (selectedItem && selectedItem.id === b.id));
           
            let controlValues = [];

            if (this.parents) {
                this.parents.forEach(x => controlValues.push({name: `${x.lastName} ${x.firstName} ${x.middleName ?? ""} ${x.dateOfBirth ? new Date(x.dateOfBirth).toLocaleDateString('ru-RU'): ""} (${this.parentTypes.find(i => i.name === x.type).description})`, value: x.id}))
            }

            const control = this.form.get('parent') as DropDownFormControl;
            control.setCollection(controlValues);
            
            if (selectedItem) {
                this.form.patchValue(cleanObject({name: `${selectedItem.lastName} ${selectedItem.firstName} ${selectedItem.middleName ?? ""} ${selectedItem.dateOfBirth ? new Date(selectedItem.dateOfBirth).toLocaleDateString('ru-RU') : ""} (${this.parentTypes.find(i => i.name === selectedItem.type).description})`, value: selectedItem.id}));
            }
        });
    }

    public onSubmit() {
        this.isFormDisabled = true;
        const item = this.parents.find(x => x.id == this.form.getRawValue().parent)
        this.form.disable();

        this.onSave.next(item);
        this.hide();
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}