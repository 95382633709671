import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DateFormControl } from './date-form-control';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';

@Component({
    selector: 'app-date-form-control',
    templateUrl: './date-form-control.component.html',
})
export class DateFormControlComponent extends AbstractFormControlComponent<DateFormControl> implements OnInit, OnDestroy {
    @Input() has18YearsOldRestriction: boolean = false;

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    @Input() set maxDate(value: Date) {
        if (value) {
            this.control['maxDate'] = new Date(new Date(value).setHours(23, 59, 59, 999));
        } else {
            this.control['maxDate'] = value;
        }
    }

    @Input() set minDate(value: Date) {
        if (value) {
            this.control['minDate'] = new Date(new Date(value).setHours(0, 0, 0, 0));
        } else {
            this.control['minDate'] = value;
        }
    }

    get maxDate(): Date {
        return this.control['maxDate'];
    }

    get minDate(): Date {
        return this.control['minDate'];
    }

    ngOnInit(): void {
        if (this.has18YearsOldRestriction) {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            this.maxDate = date;
        }
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }

    changeDate(event: MatDatepickerInputEvent<Date>): void {
        const date = event.value as Date;

        this.control.setValue(date);
        this.control.markAsTouched();
    }

    open(): void {
        if (this.has18YearsOldRestriction && !this.control.value) {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 40);
            this.changeDate({ value: date, target: null, targetElement: null });
        }
    }
}
