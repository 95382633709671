<app-navigation [title]="title" [subTitle]="subTitle"></app-navigation>

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-10">
            </div>
            <div class="col-2">
                <button class="btn blue btn-sm float-right" routerLink="{{ createLinkUrl }}">Добавить</button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <app-grid
            #gridCourseList
            [id]="'courses'"
            [columnDefinitions]="columnDefinitions"
            [service]="coursesService"
        ></app-grid>
    </div>
</div>
