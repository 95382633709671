<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill">
        <mat-label>{{ title }}</mat-label>
        <input matInput [class.is-invalid]="control.invalid && control.touched" mask="0*" [attr.maxlength]="control.maxLength" type="text" class="form-control form-control-sm" [formControlName]="controlName" />
    </mat-form-field>
    <mat-error>
        <span *ngIf="control.errors?.required">*Обязательно</span>
        <span *ngIf="control.errors?.minlength">
            <span *ngIf="control.minLength !== control.maxLength">Введите не менее {{ control.minLength }} цифр.</span>
            <span *ngIf="control.minLength === control.maxLength">Ввудите {{ control.minLength }} цифр.</span>
        </span>
        <span *ngIf="control.errors?.maxlength">Ввод более {{ control.maxLength }} цифр не допускается.</span>
    </mat-error>   
</div>
