import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { NameFormControl } from 'src/app/shared/form/controls/name-form-control/name-form-control';
import { NumberFormControl } from 'src/app/shared/form/controls/number-form-control/number-form-control';
import { markFormGroupTouched } from 'src/app/shared/form/extensions';
import { Location } from '@angular/common';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { IEnum } from 'src/app/models/enum.model';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { DropDownFormControl } from 'src/app/shared/form/controls/dropdown-form-control/dropdown-form-control';
import { AdditionalFieldsService } from '../additionalFields.service';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { CheckBoxFormControl } from 'src/app/shared/form/controls/checkbox-form-control/checkbox-form-control';

@Component({
    selector: 'app-additionalField-information',
    templateUrl: './additionalField-information.component.html',
    styleUrls: ['./additionalField-information.component.scss'],
})
export class AdditionalFieldInformationComponent extends BaseTabPageComponent implements OnDestroy {

    public additionalFieldForm: FormGroup = new FormGroup({
        id: new NumberFormControl(false, true),
       
        entityType: new DropDownFormControl(true, { keyPropertyName: 'name', valuePropertyName: 'description' }),
        name: new NameFormControl(true),
        isShowInGrid: new CheckBoxFormControl(true),
    });

    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    subTitle: string = 'Дополнительное поле';

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private knowledgeBaseService: KnowledgeBaseService,
        public additionalFieldsService: AdditionalFieldsService,
        private toast: ToastService,
        public location: Location
    ) {
        super(null, true, null, router);
        
        this.subscriptionHandler.subscriptions = knowledgeBaseService.additionalFieldEntityTypesSource$.subscribe((model: IEnum[]) => {
            const control = this.additionalFieldForm.get('entityType') as DropDownFormControl;
            control.setCollection(model);
        });
        
        this.init();
    }

    init(): void {
        if (this.isCreationMode) {
            this.title = 'Создание дополнительного поля';
            this.deleteEnabled = false;
        } else {
            this.subscriptionHandler.subscriptions = this.additionalFieldsService.get(this.getIdFromUrl()).subscribe((model: IAdditionalField) => {
                this.updateForm(model);
            });
        }
    }

    save(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
            if (!this.additionalFieldForm.valid) {
                this.toast.required();

                markFormGroupTouched(this.additionalFieldForm);

                resolve(false);

                return;
            }

            this.saveField(resolve);
        });

        return promise;
    }

    saveField(resolve): void {
        const entity = this.additionalFieldForm.getRawValue() as IAdditionalField;

        if (!entity.id) {
            this.additionalFieldsService
                .add(entity)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: IAdditionalField) => {
                    resolve(true);
                    this.updateNavigation(model);
                    this.toast.success(`Дополнительное поле`, 'Добавлено');
                });

            return;
        }

        this.additionalFieldsService
            .update(entity.id, entity)
            .pipe(
                catchError((err) => {
                    resolve(false);
                    return throwError(err);
                })
            )
            .subscribe((model: IAdditionalField) => {
                resolve(true);
                this.updateNavigation(model);
                this.toast.success(`Дополнительное поле`, 'Изменено');
            });
    }

    delete(): void | Promise<boolean> {
        const promise = new Promise<boolean>((resolve) => {
           
            const id = this.getIdFromUrl();
            if (id) {
                this.additionalFieldsService
                .delete(id)
                .pipe(
                    catchError((err) => {
                        resolve(false);
                        return throwError(err);
                    })
                )
                .subscribe((model: any) => {
                    resolve(true);
                    this.toast.success(`Дополнительное поле`, 'Удалено');
                });
            }
        });

        return promise;
    }

    get isCreationMode(): boolean {
        return this.activeRoute.snapshot.paramMap.get('id') === 'create';
    }

    private updateNavigation(model: IAdditionalField) {
        this.updateForm(model);
        this.url = `/additionalFields/${model.id}`;
    }

    private updateForm(model: IAdditionalField)
    {
        this.title = `(ДП) ${model.name}`;
        this.deleteEnabled = true;
        this.additionalFieldForm.patchValue(model);        
    }

    private getIdFromUrl(): number {
        return Number(this.activeRoute.snapshot.paramMap.get('id'));
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
