import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
})
export class AlertModalComponent implements OnInit {
    public body: string;
    public title: string = 'Alert';
    public onClose: Subject<boolean>;

    public constructor(private bsModalRef: BsModalRef) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public show(body: string): void {
        this.body = body;
    }

    public showWithTitleAndBody(title: string, body: string): void {
        this.body = body;
        this.title = title;
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.onClose.complete();
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.onClose.next(null);
        this.onClose.complete();
        this.bsModalRef.hide();
    }
}
