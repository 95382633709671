import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/shared/components/grid/data.service';
import { IPupil } from 'src/app/models/pupil.model';
import { Observable } from 'rxjs';
import { replaceFilterQuery } from 'src/app/shared/extensions/extensions';

@Injectable({
    providedIn: 'root',
})
export class PupilsService extends DataService<IPupil> {
   
    private ageSearch: boolean = false;

    constructor(private http: HttpClient, private configService: ConfigService) {
        super();
    }

    setCommonFilters(ageSearch: boolean) {
        this.ageSearch = ageSearch;
    }

    get(id: number): Observable<IPupil> {
        return this.http.get<IPupil>(`${this.configService.apiURI}/api/pupils/${id}`);
    }

    getforCourse(): Observable<IPupil[]> {
        return this.http.get<IPupil[]>(`${this.configService.apiURI}/api/pupils/forCourse`);
    }

    getforGroup(): Observable<IPupil[]> {
        return this.http.get<IPupil[]>(`${this.configService.apiURI}/api/pupils/forGroup`);
    }

    getforParent(): Observable<IPupil[]> {
        return this.http.get<IPupil[]>(`${this.configService.apiURI}/api/pupils/forParent`);
    }

    update(id: number, data: IPupil): Observable<IPupil> {
        return this.http.put<IPupil>(`${this.configService.apiURI}/api/pupils/${id}`, data);
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.configService.apiURI}/api/pupils/${id}`);
    }

    add(data: any): Observable<IPupil> {
        return this.http.post<IPupil>(`${this.configService.apiURI}/api/pupils/`, data);
    }

    odata(query: string): Observable<any> {
        query = this.formatQuery(query);
       
        return this.http.post(
            `${this.configService.apiURI}/odata/pupils?$expand=Group&searchAge=${this.ageSearch}`,
            { query }
        );
    }

    formatQuery(query: string): string {
        const filterQuery = this.getQueryBy('filter', query);

        if (filterQuery) {
            let replacedFilterQuery = replaceFilterQuery(filterQuery, 'Courses', 'Id');

            query = query.replace(filterQuery, replacedFilterQuery);
            console.log(query);
        }

        return query;
    }
}
