import { FormGroup } from '@angular/forms';
import { NameFormControl } from './controls/name-form-control/name-form-control';
import { IEnum } from 'src/app/models/enum.model';

function markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
        control.markAsTouched();
        control.updateValueAndValidity();

        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
}

function cleanObject(obj: any): any {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        } else if (obj[propName] instanceof Object) {
            cleanObject(obj[propName]);
        }
    }

    return obj;
}

function setValueInNameControl(control: NameFormControl, items: IEnum[]) {
    const stateValue = control.value;
    items.forEach((item) => {
        if (stateValue === item.name) {
            control.patchValue(item.description);
        }
    });
}

export { markFormGroupTouched, cleanObject, setValueInNameControl };
