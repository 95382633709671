import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DropDownFormControl } from './dropdown-form-control';

@Component({
    selector: 'app-dropdown-form-control',
    templateUrl: './dropdown-form-control.component.html',
})
export class DropDownFormControlComponent extends AbstractFormControlComponent<DropDownFormControl> {
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    change(value): void {
        this.onChange.emit(value);
    }

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get hiddenOptions(): any[] {
        return this.control.hiddenOptions || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || 'name';
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || 'name';
    }

    public get useKeyForValue(): boolean {
        return this.control.useKeyForValue || false;
    }

    public get disableChooseOption(): boolean {
        return this.control.disableChooseOption || false;
    }
}
