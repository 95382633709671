import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class NameFormControl extends AbstractFormControl {
    constructor(required: boolean = false, private options?: NameOptions, disabled: boolean = false) {
        super(required, disabled);

        if (options && options.defaultValue) {
            this.setValue(options.defaultValue);
        } else {
            this.setValue(null);
        }

        const validators = [];
        if (required) {
            validators.push(Validators.required);
        }

        if (options && options.minLength) {
            validators.push(Validators.minLength(options.minLength));
        }

        if (options && options.maxLength) {
            this.maxLength = options.maxLength;
        }
    }

    public readonly maxLength: number = 50;

    public get capitalizedEachWord(): boolean {
        return this.options?.capitalizedEachWord;
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    public get minLength(): number {
        return this.options?.minLength;
    }

    protected getValidators(): ValidatorFn[] {
        return [
            // Validators.maxLength(50)
        ];
    }
}

export interface NameOptions {
    capitalizedEachWord?: boolean;
    minLength?: number;
    maxLength?: number;
    defaultValue?: any;
    readOnlyCss?: boolean;
}
