<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill">
        <mat-label>{{ title }}</mat-label>
        <input matInput type="email" [class.is-invalid]="control.invalid && control.touched" class="form-control form-control-sm" [formControlName]="controlName" />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.minlength">{{ title }} должен содержать не менее {{ minLength }} символов.</span>
            <span *ngIf="control.errors?.maxlength">{{ title }} должен содержать не более {{ maxLength }} символов.</span>
            <span *ngIf="!control.errors?.minlength && !control.errors?.maxlength && control.errors?.email">
                Letters, digits, dot and @. Format: prefix@domain.name
                <!-- <br />
        Format prefix@domain.name -->
            </span>
        </mat-error>
    </mat-form-field>
</div>
