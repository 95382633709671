import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { fileSizeValidator } from '../../validators/fileSizeValidator';
import { fileTypeValidator } from '../../validators/fileTypeValidator';

export class PhotoFormControl extends AbstractFormControl {
    constructor(required?: boolean, private options?: PhotoOptions) {
        super(required);
        super.setValue(null);
    }

    public readonly maxSize: number = 8 * 1024; // KB

    protected getValidators(): ValidatorFn[] {
        return [fileTypeValidator(['jpg', 'jpeg', 'png', 'gif']), fileSizeValidator()];
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }
}

export interface PhotoOptions {
    readOnlyCss?: boolean;
}
