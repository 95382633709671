<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
    <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            appTrim
            *ngIf="!capitalizedEachWord"
            [class.is-invalid]="control.invalid && control.touched"
            type="text"
            class="form-control form-control-sm"
            [attr.maxlength]="maxLength"
            [formControlName]="controlName"
        />
        <input
            matInput
            appTrim
            appTitleCase
            *ngIf="capitalizedEachWord"
            [class.is-invalid]="control.invalid && control.touched"
            type="text"
            class="form-control form-control-sm"
            [attr.maxlength]="maxLength"
            [formControlName]="controlName"
        />
        <mat-error>
            <span *ngIf="control.errors?.required">*Обязательно</span>
            <span *ngIf="control.errors?.minlength">Минимальное значение должно быть {{ control.minLength }}</span>
        </mat-error>
    </mat-form-field>
</div>
