import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements OnInit {
    public body: string;
    public title: string;
    public yesButtonTitle: string = 'Да';
    public noButtonTitle: string = 'Нет';
    public onClose: Subject<boolean>;

    public constructor(private bsModalRef: BsModalRef) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public show(title: string, body: string, yesButtonTitle: string = 'Да', noButtonTitle: string = 'Нет'): void {
        this.title = title;
        this.body = body;
        this.yesButtonTitle = yesButtonTitle;
        this.noButtonTitle = noButtonTitle;
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.onClose.complete();
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.onClose.complete();
        this.bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.onClose.next(null);
        this.onClose.complete();
        this.bsModalRef.hide();
    }
}
