import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toast: ToastService, private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 401: {
                            this.toast.popup('Ошибка авторизации: доступ запрещен');

                            this.authService.logout();

                            return throwError(err);
                        }
                        case 403: {
                            this.toast.popup('Доступ запрещен');
                            this.router.navigate(['/']);

                            return throwError(err);
                        }
                        default: {
                            this.error(err);

                            return throwError(err);
                        }
                    }
                }
            })
        );
    }

    private error(error: any): void {
        if (error && error.error) {
            if (error.error.Messages) {
                this.toast.popup(error.error.Messages.join('<br/><br/>'), error.error.ShortMessage);
            } else if (error.error.Message) {
                this.toast.popup(error.error.Message);
            } else if (error.error.errors) {
                const errorKeys = Object.keys(error.error.errors);
                const errors = errorKeys.map((key) => {
                    return error.error.errors[key];
                });
                this.toast.popup(errors.join(`<br/>`));
            } else {
                this.toast.popup(error.error);
            }
        }
    }
}
