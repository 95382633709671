<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
    <div class="row">
        <div class="col-12">
            <div class="image-wrapper">
                <div *ngIf="image" class="image-container">
                    <img [attr.src]="'data:' + image.contentType + ';base64,' + image.data" />
                </div>

                <label *ngIf="!image">
                    <img *ngIf="noImage" [attr.src]="noImage.data" style="width: 100px; height: 100px; margin-left: 25px; margin-top: 25px" />
                </label>

                <input type="hidden" [formControlName]="controlName" />
                <input
                    [disabled]="control.disabled"
                    #fileInput
                    [hidden]="true"
                    id="imageupload-{{ controlId }}"
                    type="file"
                    accept="image/png,image/jpeg"
                    class="inputfile"
                    (change)="imageUpload($event)"
                    (click)="fileInput.value = null"
                />
            </div>
        </div>
    </div>
    <div class="row photo-buttons-row" [class.readonly]="readOnlyCss">
        <div class="col-12 text-center">
            <label for="imageupload-{{ controlId }}" class="btn small blue" [class.disabled]="control.disabled">Загрузить</label>
            <label (click)="onClearClick()" class="btn small red" [class.disabled]="!image || control.disabled">Удалить</label>
        </div>
    </div>
    <mat-error>
        <span *ngIf="control.errors?.required">*Обязательно</span>
        <span *ngIf="control.errors?.fileSizeValidator">{{ title }} максимум 8Мб</span>
        <span *ngIf="control.errors?.fileTypeValidator">{{ fileType }} тип файла на разрешен</span>
    </mat-error>
</div>
