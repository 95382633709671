import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Column, FieldType, Filters, Formatter, OnEventArgs, OperatorType } from 'angular-slickgrid';
import { IAdditionalField } from 'src/app/models/additionalField.model';
import { IEnum } from 'src/app/models/enum.model';
import { IPupil } from 'src/app/models/pupil.model';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { CustomFormatter } from 'src/app/shared/components/grid/formatters';
import { dateFormatter, linkFormatter } from 'src/app/shared/components/grid/formatters/formatters';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { DisabledRowRule } from 'src/app/shared/components/grid/models/disabled-row-rule';
import { BaseTabPageComponent } from 'src/app/shared/components/pages/base-tab-page.component';
import { SubscriptionHandler } from 'src/app/shared/components/subscriptionHandler';
import { CheckBoxFormControl } from 'src/app/shared/form/controls/checkbox-form-control/checkbox-form-control';
import { AdditionalFieldsService } from '../../additionalField/additionalFields.service';
import { GroupsService } from '../../groups/groups.service';
import { PupilsService } from '../pupils.service';

@Component({
    selector: 'app-pupils',
    templateUrl: './pupil-list.component.html',
    styleUrls: ['./pupil-list.component.scss'],
})
export class PupilListComponent extends BaseTabPageComponent implements OnInit, OnDestroy {
    @ViewChild('gridPupilsList') grid: GridComponent;

    subTitle: string = '';

    disabledRowRules: DisabledRowRule[];
    columnDefinitions: Column[];
        
    private subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    genderTypes: IEnum[] = [];
    groupTypes: IEnum[] = [];

    public searchForm: FormGroup = new FormGroup({
        ageSearch: new CheckBoxFormControl(),
    });

    constructor(
        private router: Router,
        public pupilsService: PupilsService,
        public groupsService: GroupsService,
        private additionalFieldsService: AdditionalFieldsService,
        private knowledgeBaseService: KnowledgeBaseService,
    ) {
        super('Воспитанники', false, false, router);
       
        this.subscriptionHandler.subscriptions = knowledgeBaseService.genderTypesSource$.subscribe((model: IEnum[]) => {
            this.genderTypes = model;
        });

        this.subscriptionHandler.subscriptions = knowledgeBaseService.groupTypeSource$.subscribe((model: IEnum[]) => {
            this.groupTypes = model;
        });

        this.subscriptionHandler.subscriptions = additionalFieldsService.getForEntityGrid('Pupil').subscribe((model: IAdditionalField[]) => {
            this.init();
            this.addAdditionalColumnDefinitions(model);
        });
    }

    ngOnInit(): void {
        this.subscriptionHandler.subscriptions = this.searchForm.valueChanges.subscribe((model: any) => {
            const ageSearch = this.searchForm.controls['ageSearch'].value;

            this.pupilsService.setCommonFilters(ageSearch);
            this.grid.angularGrid.paginationService.goToFirstPage();
        });
    }

    get createLinkUrl(): string {
        return `/pupils/create`;
    }

    init(): void {
        this.disabledRowRules = [
            {
                properties: [{ property: 'isActive', value: false }],
            },
        ];

        this.columnDefinitions = [
            {
                id: 'LastName',
                name: 'Фамилия',
                field: 'lastName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: linkFormatter,
                onCellClick: this.lastNameClick.bind(this),
            },
            {
                id: 'MiddleName',
                name: 'Отчество',
                field: 'middleName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'FirstName',
                name: 'Имя',
                field: 'firstName',
                type: FieldType.string,
                sortable: true,
                filterable: true,
            },
            {
                id: 'GroupId',
                name: 'Група',
                field: 'groupId',
                type: FieldType.number,
                sortable: true,
                filterable: true,
                formatter: this.groupFormatter,
                filter: {
                    collectionAsync: this.groupsService.short(),
                    customStructure: {
                        value: 'id',
                        label: 'name',
                        optionLabel: 'name', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'Gender',
                name: 'Пол',
                field: 'gender',
                type: FieldType.string,
                sortable: true,
                filterable: true,
                formatter: this.genderTypeFormatter,
                filter: {
                    collectionAsync: this.knowledgeBaseService.genderTypesSource$,
                    customStructure: {
                        value: 'name',
                        label: 'description',
                        optionLabel: 'description', // if selected text is too long, we can use option labels instead
                    },
                    collectionOptions: {
                        separatorBetweenTextLabels: ' ',
                        filterResultAfterEachPass: 'chain', // options are "merge" or "chain" (defaults to "chain")
                    },
                    model: Filters.multipleSelect,
                },
            },
            {
                id: 'DateOfBirth',
                name: 'Дата Рождения',
                field: 'dateOfBirth',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },
            {
                id: 'Age',
                name: 'Возраст',
                field: 'age',
                type: FieldType.number,
                sortable: true,
                filterable: true,
            },
            {
                id: 'StartOn',
                name: 'Дата Приема',
                field: 'startOn',
                type: FieldType.date,
                sortable: true,
                filterable: true,
                formatter: dateFormatter,
                filter: {
                    model: Filters.dateRange,
                },
            },                    
            {
                id: 'IsActive',
                name: 'Активный',
                field: 'isActive',
                type: FieldType.boolean,
                sortable: true,
                filterable: true,
                filter: {
                    collection: [
                        { isActive: '', label: 'Выбрать все' },
                        { isActive: true, label: 'Активный' },
                        { isActive: false, label: 'Неактивный' },
                    ],
                    customStructure: {
                        value: 'isActive',
                        label: 'label',
                    },
                    model: Filters.singleSelect,
                },
                formatter: CustomFormatter.statusFormatter,
            }
        ];
    }

    addAdditionalColumnDefinitions(additionalFields: IAdditionalField[]): void {
        if (additionalFields) {
            additionalFields.forEach(x => {
                this.columnDefinitions.push({
                    id: `AdditionalField.${x.id}`,
                    name: x.name,
                    field: `additionalField.${x.id}`,
                    type: FieldType.string,
                    formatter: CustomFormatter.additionalFieldFormatter,
                    sortable: false,
                    filterable: false,
                });
            });
        }
    }

    private lastNameClick(e: KeyboardEvent | MouseEvent, args: OnEventArgs): void {
        const model = args.dataContext as IPupil;
       
        this.router.navigate([`/pupils/${model.id}`]);
    }

    private genderTypeFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return this.genderTypes.find(i => i.name === value).description;
    };

    private groupFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {
        return dataContext.group ? dataContext.group.number + ' - ' + this.groupTypes.find(i => i.name === dataContext.group.type).description : "";
    };

    save(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }

    delete(): void | Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    
    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribeAll();
    }
}
